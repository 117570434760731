/* @flow */
import React from 'react';
import { TouchableOpacity } from '@pluralcom/blueprint';

import { FontAwesomeIcon } from '../../..';

import styles from './ButtonClose.module.scss';

const ButtonClose = ({
  className,
  ...rest
}: {
  className: string,
  rest: any,
}) => (
  <TouchableOpacity className={styles['button-close']} {...rest}>
    <FontAwesomeIcon icon={['fas', 'times']} />
  </TouchableOpacity>
);

export default ButtonClose;
