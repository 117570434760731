/**
 * @generated SignedSource<<5cc1948b6f295b1443719972d7d32caf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UsersSectionItem_user$data = {
  readonly avatar: {
    readonly id: string;
    readonly smallThumbnail: string | null;
  } | null;
  readonly first_name: string | null;
  readonly id: string;
  readonly last_name: string | null;
  readonly location: {
    readonly id: string;
    readonly name: string | null;
  } | null;
  readonly name: string | null;
  readonly network_info: {
    readonly id: string;
    readonly known_emails_phones: ReadonlyArray<string | null> | null;
    readonly subtitle: string | null;
  } | null;
  readonly profile_color: string | null;
  readonly username: string | null;
  readonly " $fragmentType": "UsersSectionItem_user";
};
export type UsersSectionItem_user$key = {
  readonly " $data"?: UsersSectionItem_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UsersSectionItem_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UsersSectionItem_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "first_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last_name",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profile_color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "avatar",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "smallThumbnail",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Location",
      "kind": "LinkedField",
      "name": "location",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNetworkInfo",
      "kind": "LinkedField",
      "name": "network_info",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subtitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "known_emails_phones",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "f0dd6866142e0993b2055d9b1f92dc77";

export default node;
