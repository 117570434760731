/* @flow */

/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';
import { commitMutation } from 'react-relay';
import type { Environment } from 'react-relay';

type Problem = 'spam_scam' | 'offensive' | 'impersonation' | 'other';
type Entity = 'activity' | 'message' | 'skill' | 'user';

export default (environment: Environment) => (
  id: string,
  entity?: Entity = null,
  problem: Problem
): void =>
  new Promise((resolve, reject) => {
    const mutation = graphql`
      mutation ReportEntityMutation($input: ReportEntityInput!) {
        reportEntity(input: $input) {
          error {
            message
          }
        }
      }
    `;

    const variables = {
      input: {
        entity_id: id,
        entity_type: entity,
        problem,
      },
    };

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: resolve,
      onError: reject,
    });
  });
