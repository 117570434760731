/* @flow */
import React, { useState } from 'react';
import classNames from 'classnames';
import { Button, ButtonFlat } from '@pluralcom/blueprint';

import Modal from '../Modal/Modal';
import FontAwesomeIcon from '../FontAwesomeIcon/FontAwesomeIcon';
import ButtonClose from '../ButtonClose/ButtonClose';
import Text from '../Text/Text';
import FancyDialog from '../FancyDialog/FancyDialog';
import PageIndicators from '../PageIndicators/PageIndicators';

import styles from './ModalWizardMultiple.module.scss';

type Props = {
  // modal props
  isOpen: boolean,
  toggle: Function,
  // own props
  className?: ?string,
  title?: ?string,
  description?: ?string,
  moreInfoButtonText?: ?string,
  moreInfoDialogProps?: ?string,
  children?: ?React$Node,
  CloseButton?: Function,
  closeBtnOnClick?: Function,
  noCloseButton?: ?boolean,
  nextButtonProps?: ?Object,
  backButtonProps?: ?Object,
  // classNames
  contentClassName?: ?string,
  bodyClassName?: ?string,
  footerClassName?: ?string,
  titleClassName?: ?string,
  descriptionClassName?: ?string,
  moreInfoButtonClassName?: ?string,
  headerClassName?: string,
  indicators?: {
    length: number,
    activeIndex: number,
  },
  renderHeader: Function,
  renderNextButton: Function,
  closeBtnProps: Object,
};

/** Default dialog close button */
const CloseButton = ({ ...rest }) => (
  <ButtonClose
    absolute
    size="md"
    className={classNames(styles['btn-close'])}
    {...rest}
  />
);

const ModalWizardMultiple = ({
  // modal props
  isOpen,
  toggle,
  // own props
  className,
  children,
  title,
  description,
  moreInfoButtonText = 'More info',
  moreInfoDialogProps,
  CloseButton: InCloseButton = CloseButton,
  closeBtnOnClick,
  // noCloseButton = true,
  noCloseButton = true,
  nextButtonProps,
  backButtonProps,
  // classNames
  contentClassName,
  bodyClassName,
  footerClassName,
  titleClassName,
  descriptionClassName,
  moreInfoButtonClassName,
  headerClassName,
  closeBtnProps = {},
  indicators,
  renderHeader,
  renderNextButton,
  ...rest
}: Props) => {
  const [showDialogHelp, setshowDialogHelp] = useState(false);
  const _toggleDialogHelp = () => setshowDialogHelp((currVal) => !currVal);

  return (
    <Modal
      className={classNames([styles.modal, className])}
      toggle={toggle}
      isOpen={isOpen}
      {...rest}
    >
      <div className={classNames([styles.content, contentClassName])}>
        {noCloseButton ? null : (
          <InCloseButton
            onClick={closeBtnOnClick || toggle}
            {...closeBtnProps}
          />
        )}
        {renderHeader ? renderHeader() : null}
        {title || description ? (
          <header className={classNames(styles.header, headerClassName)}>
            <Text
              element="h4"
              className={classNames([styles.title, titleClassName])}
            >
              {title}
            </Text>
            {description ? (
              <Text
                className={classNames([
                  styles.description,
                  descriptionClassName,
                ])}
              >
                {description}
                {moreInfoDialogProps ? (
                  <Button
                    className={classNames([
                      styles['btn-more-info'],
                      moreInfoButtonClassName,
                    ])}
                    onClick={_toggleDialogHelp}
                  >
                    <FontAwesomeIcon
                      icon={['far', 'lightbulb']}
                      className={classNames([
                        styles['title__more-info__icon'],
                        moreInfoButtonClassName,
                      ])}
                    />{' '}
                    {moreInfoButtonText}
                  </Button>
                ) : null}
                {moreInfoDialogProps ? (
                  <FancyDialog
                    isOpen={showDialogHelp}
                    toggle={_toggleDialogHelp}
                    primaryBtnText="Got it!"
                    primaryBtnOnClick={_toggleDialogHelp}
                    {...moreInfoDialogProps}
                  />
                ) : null}
              </Text>
            ) : null}
          </header>
        ) : null}
        <div className={classNames([styles.body, bodyClassName])}>
          {children}
        </div>
        {backButtonProps || nextButtonProps ? (
          <footer className={classNames([styles.footer, footerClassName])}>
            {backButtonProps ? (
              <ButtonFlat
                uiType="secondary"
                {...backButtonProps}
                className={classNames([
                  styles.footer__btn,
                  styles['footer__btn--secondary'],
                  backButtonProps?.className,
                ])}
                text={backButtonProps.text || 'Back'}
              />
            ) : null}
            {indicators && (
              <PageIndicators className={styles.indicators} {...indicators} />
            )}
            {nextButtonProps ? (
              <ButtonFlat
                uiType="primary"
                {...nextButtonProps}
                className={classNames(
                  [styles.footer__btn],
                  nextButtonProps?.className,
                )}
                text={nextButtonProps.text || 'Next'}
              />
            ) : null}
            {renderNextButton ? renderNextButton() : null}
          </footer>
        ) : null}
      </div>
    </Modal>
  );
};

export default ModalWizardMultiple;
