/* @flow */
import { localStorageService } from '@pluralcom/plural-web-utils';
import isFunction from 'lodash/isFunction';

type AvatarType = {
  smallThumbnail?: ?string,
  thumbnail?: ?string,
  medium?: ?string,
  original?: ?string,
};

type UserType = {
  id: string,
  username: string,
  first_name: string,
  last_name: string,
  name: string,
  email: ?string,
  phone: ?string,
  avatar?: ?AvatarType,
  published_at?: string,
  created_at?: string,
};

const KEYS = {
  user: 'user',
};

const getUser = (): UserType => localStorageService.getItem(KEYS.user);

const setUser = (user: UserType | ((UserType | null) => UserType)): void => {
  localStorageService.setItem(
    KEYS.user,
    isFunction(user) ? user(getUser()) : user,
  );
};

const getAvatar = (): { id: string, smallThumbnail?: string } | null =>
  getUser()?.avatar;

const setAvatar = (avatar) => {
  const user = getUser();
  if (!user) {
    return null;
  }
  const avatarToSet = { ...(user ? user.avatar : {}), ...avatar };
  return setUser({
    ...user,
    avatar: Object.keys(avatarToSet).length ? avatarToSet : null,
  });
};

export { setUser, getUser, getAvatar, setAvatar };
