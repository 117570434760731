/* @flow */
import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';

import { sentryHelpers } from '../../utils';

const RedirectNotFound = ({ location }: { location: Location }) => {
  sentryHelpers.captureEvent(
    {
      message: 'Redirecting to 404 Not found',
      level: 'warning',
    },
    {
      extra: {
        previousPathname: location.pathname,
      },
    },
  );
  return <Redirect to={{ pathname: '/404' }} />;
};

export default withRouter(RedirectNotFound);
