/**
 * @generated SignedSource<<14701eb9d67883a07e094fd1356728c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GenderType = "FEMALE" | "MALE" | "NOT_SPECIFIED" | "%future added value";
export type EditProfileInput = {
  bio?: string | null;
  birthdate?: string | null;
  clientMutationId?: string | null;
  first_name?: string | null;
  gender?: GenderType | null;
  last_name?: string | null;
  location?: LocationInput | null;
  profile_color?: string | null;
  socialProfiles?: SocialProfilesInputType | null;
  username?: string | null;
  website?: string | null;
};
export type SocialProfilesInputType = {
  behance?: string | null;
  buymeacoffee?: string | null;
  dribbble?: string | null;
  flickr?: string | null;
  github?: string | null;
  instagram?: string | null;
  linkedin?: string | null;
  medium?: string | null;
  patreon?: string | null;
  pinterest?: string | null;
  quora?: string | null;
  snapchat?: string | null;
  stackoverflow?: string | null;
  substack?: string | null;
  tiktok?: string | null;
  tumblr?: string | null;
  twitter?: string | null;
  vimeo?: string | null;
  youtube?: string | null;
};
export type LocationInput = {
  city?: string | null;
  country?: string | null;
  country_code?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  offset?: string | null;
  postal_code?: string | null;
  zoom_level?: number | null;
};
export type EditProfileMutation$variables = {
  input: EditProfileInput;
};
export type EditProfileMutation$data = {
  readonly editProfile: {
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly profile: {
      readonly avatar: {
        readonly id: string;
        readonly smallThumbnail: string | null;
        readonly thumbnail: string | null;
      } | null;
      readonly bio: string | null;
      readonly birthdate: string | null;
      readonly email: string | null;
      readonly first_name: string | null;
      readonly gender: GenderType | null;
      readonly id: string;
      readonly last_name: string | null;
      readonly location: {
        readonly id: string;
        readonly latitude: number;
        readonly longitude: number;
        readonly name: string | null;
      } | null;
      readonly phone: string | null;
      readonly profile_color: string | null;
      readonly socialProfiles: {
        readonly behance: string | null;
        readonly buymeacoffee: string | null;
        readonly dribbble: string | null;
        readonly flickr: string | null;
        readonly github: string | null;
        readonly id: string;
        readonly instagram: string | null;
        readonly linkedin: string | null;
        readonly medium: string | null;
        readonly patreon: string | null;
        readonly pinterest: string | null;
        readonly quora: string | null;
        readonly snapchat: string | null;
        readonly stackoverflow: string | null;
        readonly substack: string | null;
        readonly tiktok: string | null;
        readonly tumblr: string | null;
        readonly twitter: string | null;
        readonly vimeo: string | null;
        readonly youtube: string | null;
      } | null;
      readonly username: string | null;
      readonly website: string | null;
    } | null;
  } | null;
};
export type EditProfileMutation = {
  response: EditProfileMutation$data;
  variables: EditProfileMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "profile",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "first_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "birthdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bio",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "website",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gender",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profile_color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "avatar",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "smallThumbnail",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Location",
      "kind": "LinkedField",
      "name": "location",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latitude",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "longitude",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SocialProfiles",
      "kind": "LinkedField",
      "name": "socialProfiles",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "instagram",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "github",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dribbble",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "twitter",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "linkedin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "medium",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "youtube",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vimeo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "flickr",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "behance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "snapchat",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pinterest",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quora",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tumblr",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "stackoverflow",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "patreon",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "buymeacoffee",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tiktok",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "substack",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditProfilePayload",
        "kind": "LinkedField",
        "name": "editProfile",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditProfilePayload",
        "kind": "LinkedField",
        "name": "editProfile",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6815fbed28ba1c1f50e4e7e4de8f54d2",
    "id": null,
    "metadata": {},
    "name": "EditProfileMutation",
    "operationKind": "mutation",
    "text": "mutation EditProfileMutation(\n  $input: EditProfileInput!\n) {\n  editProfile(input: $input) {\n    profile {\n      id\n      username\n      first_name\n      last_name\n      email\n      phone\n      birthdate\n      bio\n      website\n      gender\n      profile_color\n      avatar {\n        id\n        thumbnail\n        smallThumbnail\n      }\n      location {\n        id\n        latitude\n        longitude\n        name\n      }\n      socialProfiles {\n        id\n        instagram\n        github\n        dribbble\n        twitter\n        linkedin\n        medium\n        youtube\n        vimeo\n        flickr\n        behance\n        snapchat\n        pinterest\n        quora\n        tumblr\n        stackoverflow\n        patreon\n        buymeacoffee\n        tiktok\n        substack\n      }\n    }\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d99d1e4946aaede80d9192f6f1466638";

export default node;
