/* @flow */
import React from 'react';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import PersonListItem from '../../../../../List/PersonListItem/PersonListItem';

import type { ProfileListItem } from '../../../../../../../../flow';

import styles from './UsersSectionItem.module.scss';

type Props = {
  /** User */
  user: ProfileListItem,
  /** Callback for click event */
  onClick?: Function,
};

const UsersSectionItem = ({ user = {}, onClick, ...rest }: Props) => (
  <PersonListItem
    user={user}
    onClick={() => onClick(user)}
    avatar={user.avatar}
    title={user.name || `${user.first_name} ${user.last_name}`.trim()}
    subtitle={user.network_info?.subtitle}
    text={
      user.network_info?.known_emails_phones?.join(', ') || user?.location?.name
    }
    className={styles.item}
    {...rest}
  />
);

export default createFragmentContainer(UsersSectionItem, {
  user: graphql`
    fragment UsersSectionItem_user on User {
      id
      username
      first_name
      last_name
      name
      profile_color
      avatar {
        id
        smallThumbnail
      }
      location {
        id
        name
      }
      network_info {
        id
        subtitle
        known_emails_phones
      }
    }
  `,
});
