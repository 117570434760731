/* @flow */
import React, { useState } from 'react';
import { libphonenumberHelpers } from '@pluralcom/plural-js-utils';
import classNames from 'classnames';
import { ButtonFlat } from '@pluralcom/blueprint';

import FancyDialog from '../../../FancyDialog/FancyDialog';
import ButtonAppStore from '../../../ButtonAppStore/ButtonAppStore';
import ButtonPlayStore from '../../../ButtonPlayStore/ButtonPlayStore';
import Form from '../../../Form/Form';
import TileInputTelephoneItem from '../../../TileInputTelephoneItem/TileInputTelephoneItem';
import getAppLinkMutation from '../../../../graphql/mutations/general/GetAppLink';
import { errorTexts } from '../../../../assets/data';

import styles from './ModalGetMobileApp.module.scss';

const ModalGetMobileApp = ({ ...rest }) => {
  const [phone, setPhone] = useState({ value: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [isSent, setIsSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!phone.status) {
      setErrorMessage(errorTexts.invalidMobileNumber);
      return;
    }
    setErrorMessage('');
    setIsLoading(true);
    getAppLinkMutation({
      phone: libphonenumberHelpers.formatNumber(
        libphonenumberHelpers.parseNumber(phone.number),
      ),
    })
      .then(({ getAppLink }) => {
        setIsLoading(false);
        if (getAppLink?.error?.message) {
          return setErrorMessage(getAppLink?.error?.message);
        }
        return setIsSent(true);
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorMessage(error?.message);
      });
  };

  return (
    <FancyDialog
      noFooter
      noCloseButton={false}
      title="Get the App 📲"
      bodyText="To find your phone contacts please enable phonebook access via the Plural App."
      contentClassName={styles['fancy-dialog-container']}
      titleClassName={styles.title}
      {...rest}
    >
      <div className={styles.container}>
        <div className={styles['button-container']}>
          <ButtonAppStore className={styles.btn_appStore} />
          <ButtonPlayStore className={styles.btn_playStore} />
        </div>
        <div className={styles['devider-container']}>
          <div className={styles.devider} />
          <span className={styles['devider-text']}>or</span>
          <div className={styles.devider} />
        </div>
        <Form onSubmit={onSubmit}>
          <div className={styles['phone-input-container']}>
            <TileInputTelephoneItem
              autoFocus
              name="phone"
              error={errorMessage}
              className={styles['phone-input']}
              value={phone.value}
              placeholder="Mobile Number"
              onChange={(phoneData) => setPhone(phoneData)}
            />
          </div>
          <ButtonFlat
            size="lg"
            uiType="primary"
            className={classNames(
              styles['submit-btn'],
              (isLoading || isSent) && styles.disabled,
            )}
            type="submit"
            disabled={isLoading || isSent}
            loading={isLoading}
            text={isSent ? 'Link was sent!' : 'Send to phone'}
          />
        </Form>
      </div>
    </FancyDialog>
  );
};

export default ModalGetMobileApp;
