/* @flow */
import React from 'react';
import classNames from 'classnames';

import styles from './ListItem.module.scss';

type Props = {
  children: React$Node,
  className?: string,
};

const ListItem = ({ className, children, ...rest }: Props) => (
  <div className={classNames([styles.container, className])} {...rest}>
    {children}
  </div>
);

export default ListItem;
