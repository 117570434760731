/* @flow */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import classNames from 'classnames';
import { createPaginationContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import sortBy from 'lodash/sortBy';
import { useDebouncedCallback } from 'use-debounce';
import { COMMUNITY_ACTIVITY_TYPES } from '@pluralcom/plural-js-utils/lib/activityHelpers/constants';

import { usePrevious } from '../../hooks';
import LoadingLayout from '../LoadingLayout/LoadingLayout';
import FlatList from '../FlatList/FlatList';

import CommunityActivitiesListItem from './components/CommunityActivitiesListItem/CommunityActivitiesListItem';

import { CommunityActivitiesList_data } from './__generated__/CommunityActivitiesList_data.graphql';

import styles from './CommunityActivitiesList.module.scss';

type Props = {
  data: CommunityActivitiesList_data,
  flatListProps: ?Object,
  relay: RelayType,
  searchTerm?: ?string,
  numOfItemsLeftForRefetch?: number,
  loadMoreLimit: number,
};

const ROW_HEIGHT = 82;

const rowHeightMap = {
  [COMMUNITY_ACTIVITY_TYPES.LOCATION_CHANGED]: 82 + 200 + 10,
};

const getRowHeight = ({
  data,
  index,
}: {
  data: Array<{ type: string }>,
  index: number,
}) => {
  const itemType = data[index] && data[index].type;
  return rowHeightMap[itemType] || ROW_HEIGHT;
};

const CommunityActivitiesList = ({
  data,
  flatListProps,
  relay,
  searchTerm,
  numOfItemsLeftForRefetch = 12,
  loadMoreLimit = Infinity,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [scrollToIndex, setScrollToIndex] = useState(undefined);

  const _listScrollTop = useRef(0);
  const previousSearchTerm = usePrevious(searchTerm);
  const _firstItemId = data?.[0]?.id;

  const { edges = [] } = data?.communityActivities || {};
  const items = useMemo(
    () =>
      sortBy(
        edges.map((el) => el.node),
        ({ created_at }) => -1 * new Date(created_at).getTime(),
      ),
    [edges],
  );

  const { callback: _refetchConnectionWithSearchTerm } = useDebouncedCallback(
    (_searchTerm: string) =>
      relay.refetchConnection(10, () => {}, {
        _searchTerm,
      }),
    100,
  );

  /** scroll to top when first activity id changes - case: new message is sent or received */
  useEffect(() => {
    setScrollToIndex(0);
  }, [_firstItemId]);

  /** Refetch the connection if the search term changes */
  useEffect(() => {
    if (searchTerm !== previousSearchTerm) {
      _refetchConnectionWithSearchTerm(searchTerm);
    }
  }, [searchTerm, _refetchConnectionWithSearchTerm, previousSearchTerm]);

  const { callback: _loadMore } = useDebouncedCallback(() => {
    if (
      isLoading ||
      !relay.hasMore() ||
      relay.isLoading() ||
      items.length >= loadMoreLimit
    ) {
      return;
    }
    setIsLoading(true);
    relay.loadMore(10, () => {
      setIsLoading(false);
    });
  }, 100);

  const _handleListScroll = (options) => {
    /* reset scrollToIndex on scroll value so it can be set again */
    setScrollToIndex(undefined);
    const { scrollTop, scrollHeight } = options;
    const isScrollingDown = scrollTop - _listScrollTop.current > 0;
    const itemsLeftCount = Math.floor((scrollHeight - scrollTop) / ROW_HEIGHT);
    if (
      !isLoading &&
      isScrollingDown &&
      numOfItemsLeftForRefetch >= itemsLeftCount &&
      relay.hasMore()
    ) {
      _loadMore();
    }
    _listScrollTop.current = scrollTop;
  };

  const _renderListItem = ({ data: _data, index, key, style }: any) => {
    const item = (_data && _data[index]) || {};

    return (
      <div key={key} style={style}>
        <CommunityActivitiesListItem data={item} className={styles.item} />
      </div>
    );
  };

  return (
    <div className={classNames(styles.container)}>
      <div className={styles['list-container']}>
        {searchTerm && items.length === 0 && (
          <div className={styles.noResults}>No Results</div>
        )}
        <FlatList
          scrollToIndex={scrollToIndex}
          scrollToAlignment="center"
          className={styles.list}
          onScroll={_handleListScroll}
          data={items}
          rowHeight={getRowHeight}
          rowRenderer={_renderListItem}
          {...flatListProps}
          footerRenderer={
            isLoading
              ? ({
                  data: currData,
                  style,
                  index,
                }: {
                  data: Array<{ id: string }>,
                  index: number,
                  style: Object,
                }) => (
                  <div key={currData[index].id} style={style}>
                    <LoadingLayout />
                  </div>
                )
              : null
          }
          footerHeight={ROW_HEIGHT}
          showFooter={isLoading}
        />
      </div>
    </div>
  );
};

export { CommunityActivitiesList as PureCommunityActivities };
export default createPaginationContainer(
  CommunityActivitiesList,
  {
    data: graphql`
      fragment CommunityActivitiesList_data on ViewerType
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "String" }
      ) {
        communityActivities(first: $count, after: $cursor)
          @connection(key: "CommunityActivities_communityActivities") {
          edges {
            node {
              id
              type
              created_at
              ...CommunityActivitiesListItem_data
            }
          }
        }
      }
    `,
  },
  {
    getVariables(props, { count, cursor }, ...rest) {
      return {
        ...rest,
        count,
        cursor,
      };
    },
    query: graphql`
      query CommunityActivitiesListPaginationQuery(
        $count: Int!
        $cursor: String
      ) {
        viewer {
          ...CommunityActivitiesList_data
            @arguments(count: $count, cursor: $cursor)
        }
      }
    `,
  },
);
