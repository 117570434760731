/* @flow */
import React from 'react';
import TileInputItem from '../../../TileInputItem/TileInputItem';
import styles from './TileInput.module.scss';

type Props = {};

const TileInput = ({ ...rest }: Props) => (
  <TileInputItem
    className={styles.input}
    containerClassName={styles.container}
    {...rest}
  />
);

export default TileInput;
