/* @flow */

import branch from 'branch-sdk';
import urlJoin from 'url-join';
import { sharedContentGenerator } from '@pluralcom/plural-js-utils';

import getWebUrl from '../getUrlHelpers/getWebUrl';
import sentryHelpers from '../sentryHelpers/sentryHelpers';
import logger from '../logger/logger';
import environment from '../environment/environment';

const COMMON_BRANCH_CUSTOM_METADATA = {
  env: environment.getHostEnv(),
};

/**
 * Branch error middleware
 */
const _errorMiddleware = (error, { skill_id, username, desktopUrl }) => {
  const errorMessage = `Error: Branch.io: Loading branch io link failed for, skill_id: ${skill_id}, username: ${username}, desktopUrl: ${desktopUrl}`;
  logger.debugError(errorMessage, error);
  sentryHelpers.addBreadcrumb({
    level: 'warning',
    category: 'branchio_error',
    message: errorMessage,
    data: error,
  });
  sentryHelpers.captureException(error);
};

/**
 * This method will try to get a branch link. If creating the
 * branch link fails, we reject the promise & return the
 * desktopUrl (the parent can then choose what todo)
 */
const getBranchLinkSkill = (
  skill: {
    id: string,
    title: string,
    media?: {
      edges?: Array<{
        node?: {
          image?: {
            medium?: string,
          },
        },
      }>,
    },
    user: {
      id: string,
      first_name: string,
      last_name: string,
      avatar?: { medium?: string },
    },
  },
  {
    disableErrorMiddleware,
  }: {
    disableErrorMiddleware?: boolean,
  } = {},
) =>
  new Promise((resolve, reject) => {
    const { user, id: skill_id } = skill;
    const { id: user_id, username } = user;

    const desktopUrl = urlJoin(getWebUrl(), `skills/${skill_id}`);

    const linkData = {
      data: {
        // ...COMMON_BRANCH_DATA,
        $og_title: sharedContentGenerator.shareTexts.skill(
          `${user.first_name || ''} ${user.last_name || ''}`.trim(),
          skill.title,
        ),
        $og_image_url: sharedContentGenerator.getSkillSharingImage({
          ...skill,
          media: skill.shareLinkMedia || skill.media,
        }),
        $og_url: desktopUrl,
        $desktop_url: desktopUrl,
        $amount: skill.price ? skill.price : undefined,
        $currency: skill.price ? 'USD' : undefined,
        contentMetadata: {
          customMetadata: {
            ...COMMON_BRANCH_CUSTOM_METADATA,
            to: 'skill',
            user_id,
            username,
            skill_id,
          },
        },
      },
    };

    branch.link(linkData, (error, link) => {
      /**
       * Leave it up to the parent to choose what todo next.
       * E.g If this fails, the parent could fallback to desktop
       * url.
       */
      if (error) {
        if (!disableErrorMiddleware) {
          _errorMiddleware(error, { skill_id, username, desktopUrl });
        }
        const rejectRes = { desktopUrl, error };
        return reject(rejectRes);
      }

      return resolve(link);
    });
  });

/**
 * This method will try to get a branch link. If creating the
 * branch link fails, we reject the promise & return the
 * desktopUrl (the parent can then choose what todo)
 */
const getBranchLinkProfile = (
  user: {
    id: string,
    first_name: string,
    last_name: string,
    avatar?: { medium?: string },
  },
  {
    disableErrorMiddleware,
  }: {
    disableErrorMiddleware?: boolean,
  } = {},
) =>
  new Promise((resolve, reject) => {
    const desktopUrl = urlJoin(getWebUrl(), user.username || user.id);

    const linkData = {
      data: {
        // ...COMMON_BRANCH_DATA,
        $og_title: sharedContentGenerator.shareTexts.profile(
          `${user.first_name || ''} ${user.last_name || ''}`,
        ),
        $og_image_url:
          user.avatar?.medium ||
          user.avatar?.thumbnail ||
          user.avatar?.original ||
          user.avatar?.smallThumbnail ||
          undefined,
        $og_url: desktopUrl,
        $desktop_url: desktopUrl,
        contentMetadata: {
          customMetadata: {
            ...COMMON_BRANCH_CUSTOM_METADATA,
            to: 'profile',
            user_id: user.id,
            username: user.username,
          },
        },
      },
    };

    branch.link(linkData, (error, link) => {
      /**
       * Leave it up to the parent to choose what todo next.
       * E.g If this fails, the parent could fallback to desktop
       * url.
       */
      if (error) {
        if (!disableErrorMiddleware) {
          _errorMiddleware(error, { user, desktopUrl });
        }
        const rejectRes = { desktopUrl, error };
        return reject(rejectRes);
      }

      return resolve(link);
    });
  });

/**
 * This method will try to get a branch link. If creating the
 * branch link fails, we reject the promise & return the
 * desktopUrl (the parent can then choose what todo)
 */
const getBranchLinkSkillOffer = (
  skill: {
    id: string,
    title: string,
    media?: {
      edges?: Array<{
        node?: {
          image?: {
            medium?: string,
          },
        },
      }>,
    },
    user: {
      id: string,
      first_name: string,
      last_name: string,
      avatar?: { medium?: string },
    },
  },
  offer: {
    id: string,
  },
  {
    disableErrorMiddleware,
  }: {
    disableErrorMiddleware?: boolean,
  } = {},
) =>
  new Promise((resolve, reject) => {
    const { user, id: skill_id } = skill;
    const { id: user_id, username } = user;
    const { id: offer_id } = offer;

    const desktopUrl = urlJoin(
      getWebUrl(),
      `skills/${skill_id}/offers/${offer_id}`,
    );

    const linkData = {
      data: {
        // ...COMMON_BRANCH_DATA,
        $og_title: sharedContentGenerator.shareTexts.skill(
          `${user.first_name || ''} ${user.last_name || ''}`.trim(),
          skill.title,
        ),
        $og_image_url: sharedContentGenerator.getSkillSharingImage(skill),
        $og_url: desktopUrl,
        $desktop_url: desktopUrl,
        $amount: skill.price ? skill.price : undefined,
        $currency: skill.price ? 'USD' : undefined,
        contentMetadata: {
          customMetadata: {
            ...COMMON_BRANCH_CUSTOM_METADATA,
            to: 'skill',
            user_id,
            username,
            skill_id,
            offer_id,
          },
        },
      },
    };

    branch.link(linkData, (error, link) => {
      /**
       * Leave it up to the parent to choose what todo next.
       * E.g If this fails, the parent could fallback to desktop
       * url.
       */
      if (error) {
        if (!disableErrorMiddleware) {
          _errorMiddleware(error, { skill_id, username, desktopUrl });
        }
        const rejectRes = { desktopUrl, error };
        return reject(rejectRes);
      }

      return resolve(link);
    });
  });

/**
 * This method will try to get a branch link. If creating the
 * branch link fails, we reject the promise & return the
 * desktopUrl (the parent can then choose what todo)
 */
const getBranchLinkBasic = ({ customMetadata, ...rest } = {}) =>
  new Promise((resolve, reject) => {
    const desktopUrl = rest.$desktop_url || window.location.href;

    const linkData = {
      data: {
        // ...COMMON_BRANCH_DATA,
        $desktop_url: desktopUrl,
        $og_url: desktopUrl,
        $og_image_url:
          'https://media.plural.com/img/socialmedia-cards/facebook_sharing_banner.png',
        ...rest,
        contentMetadata: {
          customMetadata: {
            ...COMMON_BRANCH_CUSTOM_METADATA,
            to: 'welcome',
            ...customMetadata,
          },
        },
      },
    };

    branch.link(linkData, (error, link) => {
      /**
       * Leave it up to the parent to choose what todo next.
       * E.g If this fails, the parent could fallback to desktop
       * url.
       */
      if (error) {
        _errorMiddleware(error, { desktopUrl });
        const rejectRes = { desktopUrl, error };
        return reject(rejectRes);
      }

      return resolve(link);
    });
  });

export {
  getBranchLinkBasic,
  getBranchLinkSkill,
  getBranchLinkProfile,
  getBranchLinkSkillOffer,
};
