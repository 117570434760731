/* @flow */
import React from 'react';
import classNames from 'classnames';

import UserAvatar from '../../UserAvatar/UserAvatar';

import ListItem from '../ListItem/ListItem';

import styles from './PersonListItem.module.scss';

type Props = {
  className?: string,
  titleClassName?: string,
  subtitleClassName?: string,
  title: string,
  subtitle?: string,
  avatar?: { thumbnail: string },
  user: Object,
};

const PersonListItem = ({
  className,
  titleClassName,
  subtitleClassName,
  title,
  subtitle,
  avatar,
  user,
  ...rest
}: Props) => (
  <ListItem className={classNames(styles.container, className)} {...rest}>
    <span className={styles['avatar-container']}>
      <UserAvatar
        avatar={avatar}
        user={user}
        type={avatar && avatar.thumbnail ? 'thumbnail' : 'smallThumbnail'}
        textSize="sm"
        width="42px"
        height="42px"
      />
    </span>
    <div className={styles.header}>
      <span className={classNames(styles.title, titleClassName)}>{title}</span>
      {subtitle ? (
        <span className={classNames(styles.subtitle, subtitleClassName)}>
          {subtitle}
        </span>
      ) : null}
    </div>
  </ListItem>
);

export default PersonListItem;
