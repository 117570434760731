/* @flow */
import React from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';

import FancyDialog from '../../../FancyDialog/FancyDialog';
import {
  styles as baseModalStyles,
  bsBodyModalOpenClassName,
} from '../../../Modal/Modal';

const _handleClick = (fn) => () => {
  document.body.classList.remove(
    baseModalStyles[bsBodyModalOpenClassName],
    bsBodyModalOpenClassName,
  );
  fn();
};

const _renderFancyDialogFunc = (dialogProps) => ({
  onConfirm,
  onCancel,
}: {
  onConfirm: Function,
  onCancel: Function,
}) => (
  <FancyDialog
    isOpen
    title="Leave Page?"
    bodyText="Without saving, all changes will be discarded."
    primaryBtnText="Stay on This Page"
    primaryBtnOnClick={_handleClick(onCancel)}
    secondaryBtnText="Discard Changes"
    secondaryBtnOnClick={_handleClick(onConfirm)}
    closeBtnOnClick={_handleClick(onCancel)}
    toggle={_handleClick(onCancel)}
    {...dialogProps}
  />
);

const NavigationPromptFancyDialog = ({
  when,
  className,
  dialogProps,
  children,
  ...rest
}: {
  when: boolean,
  className?: ?string,
  children?: ?React$Node,
  dialogProps?: ?Object,
}) => (
  <NavigationPrompt when={when} {...rest}>
    {children || _renderFancyDialogFunc(dialogProps)}
  </NavigationPrompt>
);

export default NavigationPromptFancyDialog;
