/* @flow */
import React, { Component } from 'react';
import classNames from 'classnames';

import ReactTagsInput from 'react-tagsinput';
import ButtonActionDialog from '../ButtonActionDialog/ButtonActionDialog';
import SkillTag from '../SkillTag/SkillTag';
import { mixpanelHelpers } from '../../utils';
import styles from './TagsInput.module.scss';

type Props = {
  value: Array<string> | Array<Object>,
  onChange?: Function,
  onChangeInput?: Function,
  className?: string,
  onlyUnique?: boolean,
  disabled?: boolean,
  maxTags?: number,
  addKeys?: Array<number>,
  removeKeys?: Array<number>,
  addOnPaste?: boolean,
  tagDisplayProp?: string,
  tagProps?: {
    className?: string,
    classNameRemove?: string,
  },
  inputProps?: object,
  mp_location?: string,
  autoAcceptBeforeUnmount?: boolean,
  inputInputContainerRef: Function,
  renderAutosuggest: Function,
};

class TagsInput extends Component<*, Props, *> {
  _ReactTagsInputRef = null;

  focus = null;

  blur = null;

  componentDidMount = () => {
    if (this._ReactTagsInputRef) {
      this.focus = this._ReactTagsInputRef.focus;
      this.blur = this._ReactTagsInputRef.blur;
    }
  };

  componentWillUnmount() {
    const { autoAcceptBeforeUnmount } = this.props;

    if (autoAcceptBeforeUnmount && this._ReactTagsInputRef) {
      this._ReactTagsInputRef.accept();
    }
  }

  _setReactTagsInputRef = (el) => {
    this._ReactTagsInputRef = el;
  };

  _onKeyDownSpy = (_KeyDown, addTag, disableSubmit) => (e) => {
    if (disableSubmit) {
      if (typeof disableSubmit === 'function') {
        const disableSubmitResult = disableSubmit(e);
        if (disableSubmitResult) {
          return undefined;
        }
      } else {
        return undefined;
      }
    }
    /** Create new tag if user press space twice  */
    const { value } = e.target;
    if (e.keyCode === 32 && value[value.length - 1] === ' ') {
      /** handle rare case when browser add dot after word
       * if user press space twice fast */
      return addTag(value.replace('. ', ''));
    }
    return _KeyDown && _KeyDown(e);
  };

  _renderLayout = (tagComponents, inputComponent) => (
    <div className={styles.container}>
      {tagComponents}
      {inputComponent}
    </div>
  );

  _renderTag = ({
    tag,
    key,
    disabled,
    onRemove,
    className,
    classNameRemove,
    getTagDisplayValue,
    mp_location,
    withConfirmOnDelete,
    buttonActionDialogProps,
    ...rest
  }) => (
    <SkillTag
      key={key}
      className={classNames(styles.tag, className)}
      error={tag?.error}
      {...rest}
    >
      {getTagDisplayValue(tag)}
      {!disabled && (
        <ButtonActionDialog
          {...buttonActionDialogProps}
          withConfirm={withConfirmOnDelete}
          className={classNames(styles.remove, classNameRemove)}
          onClick={mixpanelHelpers.trackEventAndPass({
            name: `${mp_location ? `${mp_location}_` : ''}TagsInput_Delete`,
          })(() => onRemove(key))}
        />
      )}
    </SkillTag>
  );

  _renderInput = ({
    addTag,
    className,
    inputClassName,
    onKeyDown,
    disableSubmit,
    ...rest
  }) => {
    const { inputInputContainerRef, renderAutosuggest } = this.props;
    return (
      <SkillTag
        className={classNames([
          styles.tag,
          styles['input-container'],
          className,
        ])}
        ref={inputInputContainerRef}
      >
        <input
          type="text"
          className={classNames([styles.input, inputClassName])}
          onKeyDown={this._onKeyDownSpy(onKeyDown, addTag, disableSubmit)}
          {...rest}
        />
        {renderAutosuggest && renderAutosuggest(addTag)}
      </SkillTag>
    );
  };

  _handleChange = (tags, changedTags) => {
    const { onlyUnique, tagDisplayProp, mp_location } = this.props;
    mixpanelHelpers.trackEvent(
      `${mp_location ? `${mp_location}_` : ''}TagsInput_Add`,
    );
    let newTags = tags;
    if (!tagDisplayProp && tags.length > 0) {
      const oldTags = tags.slice(0, tags.length - 1);
      const lastTag = tags[tags.length - 1].trim();
      const comparisonLastTag = lastTag.toLowerCase();
      if (
        !onlyUnique ||
        oldTags.reduce(
          (bool, val) => bool && val.toLowerCase() !== comparisonLastTag,
          true,
        )
      ) {
        newTags = [...oldTags, lastTag];
      } else {
        newTags = oldTags;
      }
    }
    const { onChange, inputProps } = this.props;
    onChange(newTags);
    /** Reset the input field if a new tag was added */
    if (newTags.includes(changedTags[0]) && inputProps?.onChange) {
      inputProps.onChange({ target: { name: inputProps.name, value: '' } });
    }
    return this.focus && this.focus();
  };

  render() {
    const { className, ...rest } = this.props;
    return (
      <ReactTagsInput
        className={classNames(className)}
        renderInput={this._renderInput}
        renderLayout={this._renderLayout}
        renderTag={this._renderTag}
        ref={this._setReactTagsInputRef}
        {...rest}
        inputProps={{
          ...rest?.inputProps,
          disabled:
            rest?.inputProps?.disabled ||
            rest.maxTags <= rest.tagProps?.tags?.length,
        }}
        onChange={this._handleChange}
      />
    );
  }
}

TagsInput.defaultProps = {
  autoAcceptBeforeUnmount: false,
  className: undefined,
  onChangeInput: undefined,
  onlyUnique: false,
  disabled: false,
  maxTags: undefined,
  addKeys: [9, 13],
  removeKeys: [8],
  addOnPaste: true,
  tagDisplayProp: null,
  tagProps: {},
  inputProps: { placeholder: '+ Add Tag', className: undefined },
};

export default TagsInput;
