import environment from './utils/environment/environment';

/**
 * Interface for defining a route object
 */
export declare interface RouteObjType {
  /** The path of the route */
  path?: string;
  /** Whether the route should match exactly */
  exact?: boolean;
  /** The component to render for the route */
  componentStr?: string;
  /** A function to render for the route */
  render?: Function;
  /** Additional options for the route */
  _opts?: {
    /** Whether the route is protected or public */
    privacy?: 'protected' | 'public';
    /** Whether the route should be indexed by search engines */
    noIndex?: boolean;
    /** The priority of the route for search engines */
    priority?: number;
    /** The change frequency of the route for search engines */
    changefreq?:
      | 'always'
      | 'hourly'
      | 'daily'
      | 'weekly'
      | 'monthly'
      | 'yearly'
      | 'never';
  };
  /** Props to use for a redirect */
  redirectProps?: {
    /** Whether to preserve the query string in the redirect */
    preserveQueryString?: boolean;
    /** The path to redirect to */
    to?: {
      /** The pathname to redirect to */
      pathname: string;
      /** The search string to redirect to */
      search?: string;
    };
  };
  // routes?: RouteObjType,
  [x: string]: any;
}

/**
 * A list of ALL the routes in the APP
 *
 * - Used by App.js to render routes
 * - Used by build-sitemap.js to build the sitemap
 * - Used by build-robots.js to build the robots.txt file
 *
 * Highly Inspired by the ongoing react-router single config project - to avoid migration time
 */
const routes: {
  /** The routes */
  routes: RouteObjType | undefined[];
}[] = [
  {
    // component: Root,
    routes: [
      {
        path: '/',
        exact: true,
        componentStr: environment.isProduction() ? 'Landing' : undefined,
        redirectProps: environment.isProduction()
          ? undefined
          : {
              preserveQueryString: true,
              to: { pathname: '/search' },
            },
        _opts: {
          priority: 0.8,
          changefreq: 'hourly',
        },
      },
      {
        path: '/info',
        exact: true,
        componentStr: 'Landing',
      },
      {
        path: '/info',
        componentStr: 'Info',
        routes: [
          {
            path: '/info/careers',
          },
          {
            path: '/info/company',
          },
          {
            path: '/info/privacy-policy',
          },
          {
            path: '/info/terms-of-use',
            routes: [
              {
                path: '/info/terms-of-use/additional-provider-terms-of-use',
              },
              {
                path: '/info/terms-of-use/additional-customer-terms-of-use',
              },
            ],
          },
          {
            path: '/info/licences',
            _opts: {
              noIndex: true,
            },
          },
          {
            path: '/info/faq',
          },
        ],
      },
      {
        path: '/subscription/unsubscribe',
        componentStr: 'Subscription/Unsubscribe/Unsubscribe',
        _opts: {
          noIndex: true,
        },
      },
      {
        path: '/subscription/subscribe',
        componentStr: 'Subscription/Subscribe/Subscribe',
        _opts: {
          noIndex: true,
        },
      },
      {
        path: '/app/info',
        componentStr: 'Landing',
        _opts: {
          noIndex: true,
        },
      },
      {
        path: '/401',
        componentStr: 'Unauthorized',
        _opts: {
          noIndex: true,
        },
      },
      {
        path: '/404',
        componentStr: 'NotFound',
        _opts: {
          noIndex: true,
        },
      },
      {
        path: '/signup',
        componentStr: 'Signup',
      },
      {
        path: '/login',
        componentStr: 'Login',
      },
      {
        path: '/cookie-settings',
        componentStr: 'CookiesSettings',
      },
      {
        path: '/verify-code',
        componentStr: 'VerifyCode',
        _opts: {
          noIndex: true,
        },
      },
      {
        path: '/search',
        componentStr: 'Search',
        _opts: {
          privacy: environment.isProduction() ? 'protected' : 'public', // @todo @undo_production change to `privacy: 'public'`
          // privacy: 'public',
        },
      },
      {
        path: '/profile',
        exact: true,
        componentStr: 'Profile',
        _opts: {
          privacy: 'protected',
        },
      },
      {
        path: '/notifications',
        componentStr: 'Notifications',
        _opts: {
          privacy: 'protected',
        },
      },
      {
        path: '/community',
        componentStr: 'CommunityActivities',
        _opts: {
          privacy: 'protected',
        },
      },
      {
        path: '/messages',
        componentStr: 'Messenger',
        _opts: {
          privacy: 'protected',
        },
      },
      {
        path: '/notifications-external/unsubscribe',
        componentStr: 'ExternalNotifications',
        _opts: {
          noIndex: true,
        },
      },
      {
        path: '/settings/profile/location',
        componentStr: 'AddEditLocation',
        _opts: {
          privacy: 'protected',
        },
      },
      {
        path: '/settings',
        componentStr: 'Settings',
        _opts: {
          privacy: 'protected',
        },
      },
      {
        path: '/skills/create',
        accessRoles: ['user'],
        componentStr: 'CreateSkill',
        _opts: {
          privacy: 'protected',
        },
      },
      {
        path: '/skills/edit/:id/positions/create',
        componentStr: 'ExperiencePosition',
        _opts: {
          privacy: 'protected',
        },
      },
      {
        path: '/skills/edit/:skill_id/faq/create',
        componentStr: 'AnswerFaq',
        _opts: {
          privacy: 'protected',
        },
      },
      {
        path: '/skills/edit/:id/offers/create',
        componentStr: 'CreateSkillOffer',
        _opts: {
          privacy: 'protected',
        },
      },
      {
        path: '/skills/:skill_id/offers/:offerId',
        componentStr: 'Profile',
        _opts: {
          privacy: environment.isProduction() ? 'protected' : 'public', // @todo @undo_production change to `privacy: 'public'`
          // privacy: 'public',
        },
      },
      {
        path: '/skills/edit/:id/offers/edit/:offerId',
        componentStr: 'EditSkillOffer',
        _opts: {
          privacy: 'protected',
        },
      },
      {
        path: '/skills/edit/:skill_id/faq/edit/:question_id',
        componentStr: 'EditFaq',
        _opts: {
          privacy: 'protected',
        },
      },
      {
        path: '/skills/edit/:id',
        componentStr: 'EditSkill',
        _opts: {
          privacy: 'protected',
        },
      },
      {
        path: '/skills/:skill_id/review',
        componentStr: 'CreateReview',
        _opts: {
          privacy: 'protected',
        },
      },
      {
        path: '/skills/:skill_id',
        componentStr: 'Profile',
        _opts: {
          privacy: environment.isProduction() ? 'protected' : 'public', // @todo @undo_production change to `privacy: 'public'`
          // privacy: 'public',
        },
      },
      {
        path: '/experience/description/:skill_id',
        componentStr: 'ExperienceDescription',
        _opts: {
          privacy: 'protected',
        },
      },
      {
        path: '/positions/edit/:position_id',
        componentStr: 'ExperiencePosition',
        _opts: {
          privacy: 'protected',
        },
      },
      {
        path: '/referrals/create',
        componentStr: 'ReferralsCreate',
        _opts: {
          privacy: 'protected',
        },
      },
      {
        path: '/referrals',
        componentStr: 'Referrals',
        _opts: {
          privacy: 'protected',
        },
      },
      {
        path: '/review/create/:deal_id',
        componentStr: 'CreateReview',
        _opts: {
          privacy: 'protected',
        },
      },
      {
        path: '/review/:review_id',
        componentStr: 'ViewReview',
        _opts: {
          privacy: environment.isProduction() ? 'protected' : 'public', // @todo @undo_production change to `privacy: 'public'`
          // privacy: 'public',
        },
      },
      {
        path: '/payout-methods',
        componentStr: 'PayoutMethod',
        _opts: {
          privacy: 'protected',
        },
      },
      {
        path: '/payments',
        componentStr: 'Payments',
        _opts: {
          privacy: 'protected',
        },
      },
      {
        path: '/deals/:deal_id',
        componentStr: 'Deal',
        _opts: {
          privacy: 'protected',
        },
      },
      {
        path: '/room/:room_name',
        componentStr: 'Room',
        _opts: {
          privacy: 'protected',
        },
      },
      environment.isProduction()
        ? undefined
        : {
            path: '/playground',
            componentStr: 'Playground',
          },
      {
        path: '/:username',
        componentStr: 'Profile',
        _opts: {
          privacy: environment.isProduction() ? 'protected' : 'public', // @todo @undo_production change to `privacy: 'public'`
          // privacy: 'public',
        },
      },
    ].filter(Boolean),
  },
];

export default routes;
