/* @flow */
import React from 'react';
import isNil from 'lodash/isNil';
import ImageGallery from '../ImageGallery/ImageGallery';

type Props = {
  media: {
    edges: Array<{
      node: {
        image: {
          original: string,
          thumbnail: string,
          medium_wide: string,
        },
        video: {
          url: string,
        },
      },
    }>,
  },
  className: string,
  mediaPreview: boolean,
};

const isDefined = (object) => !isNil(object);
const updateItems = (media) =>
  media.edges
    .map(({ node }) => {
      if (node.image) {
        return {
          // spread node to get uploading info
          ...node,
          original:
            node?.image?.original ||
            node?.image?.medium ||
            node?.image?.medium_wide,
          thumbnail: node?.image?.thumbnail || node?.image?.smallThumbnail,
          originalAlt: 'Skill preview',
          thumbnailAlt: 'Skill preview',
        };
      }
      if (node.video) {
        return {
          youtubeUrl: node.video.url,
        };
      }
      return null;
    })
    .filter(isDefined);

const SkillMediaGallery = ({ media, ...rest }: Props) => (
  <ImageGallery items={updateItems(media)} {...rest} />
);

export default SkillMediaGallery;
