/* @flow */

import React from 'react';
import classNames from 'classnames';

import SkillMediaGallery from '../../../../../SkillMediaGallery/SkillMediaGallery';
import ImagePlaceholder from '../../../../../ImagePlaceholder/ImagePlaceholder';

import styles from './SkillMediaGalleryMini.module.scss';

type Props = {
  user?: ?{
    id: string,
  },
  media: {
    edges: Array<{
      node: {
        image: {
          original?: string,
          medium?: string,
          smallThumbnail?: string,
        },
        video: {
          url: string,
        },
      },
    }>,
  },
  className?: ?string,
  imgPlaceholderClassName?: ?string,
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl',
  hasImage?: boolean,
};

const MEDIA_SIZE_MAP = {
  xs: 'xs',
  sm: 'xs',
  md: 'xs',
  lg: 'lg',
  xl: 'lg',
  xxl: 'lg',
  xxxl: 'lg',
};

const SkillMediaGalleryMini = ({
  media,
  className,
  imgPlaceholderClassName,
  user,
  size,
  hasImage = true,
}: Props) => {
  const media_size = MEDIA_SIZE_MAP[size];

  return (
    <section
      className={classNames([
        styles.container,
        styles[`container-${media_size}`],
        className,
      ])}
    >
      {hasImage && media && media?.edges?.length > 0 ? (
        <SkillMediaGallery
          className={classNames([
            styles.gallery,
            styles[`gallery-${media_size}`],
            className,
          ])}
          showThumbnails={false}
          width="100%"
          youTubeClassName={styles.youtube}
          youTubeProps={{
            className: styles.youtube,
            autoPlay: false,
          }}
          media={media}
          mediaPreview
          disableNavigationControls
          disableSwipe
        />
      ) : (
        <ImagePlaceholder
          color={user?.profile_color}
          uuid={user?.id}
          className={classNames(
            styles.placeholder,
            styles[`placeholder-${media_size}`],
            imgPlaceholderClassName,
          )}
        />
      )}
    </section>
  );
};

export default SkillMediaGalleryMini;
