/* @flow */
import { init } from './init';
import * as sharing from './sharing';

export { init };
export * from './sharing';

const branchLinking = {
  ...sharing,
  init,
};

export default branchLinking;
