/* @flow */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';
import { createPaginationContainer } from 'react-relay';
import { Spinner, FlatList } from '@pluralcom/blueprint';
import classNames from 'classnames';

import type { UsersSection_search } from './__generated__/UsersSection_search.graphql';

import UsersSectionItem from './components/UsersSectionItem/UsersSectionItem';

import styles from './UsersSection.module.scss';

type Props = {
  relay: {
    hasMore?: func.isRequired,
    isLoading?: func.isRequired,
    loadMore?: func.isRequired,
  },
  search: UsersSection_search,
  onClickItem: Function,
  userLocation?: {
    latitude: number,
    longitude: number,
  },
};

const UsersSection = ({ search, relay, onClickItem, ...rest }: Props) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  /** data */
  const data = search?.unifiedNetwork?.edges || [];

  /** load more */
  const loadMore = useCallback(() => {
    if (relay.hasMore() && !relay.isLoading()) {
      setIsLoadingMore(true);
      relay.loadMore(10, () => setIsLoadingMore(false));
    }
  }, [relay]);

  /** ListFooter - renders LoadingLayout when isLoadingMore or isSyncPhonebook is true */
  const ListFooterComponent = useCallback(
    () => (isLoadingMore ? <Spinner /> : null),
    [isLoadingMore],
  );

  /** Renders a list item */
  const _renderListItem = ({ index, style }) => {
    const item = data[index] || {};

    return (
      item.node && (
        <div
          key={item.node.id}
          style={style}
          className={classNames(styles['item-container'])}
        >
          <UsersSectionItem user={item.node} onClick={onClickItem} />
        </div>
      )
    );
  };

  return (
    <FlatList
      data={data}
      renderItem={_renderListItem}
      loadMore={loadMore}
      loadMoreCount={10}
      isLoadingMore={isLoadingMore}
      ListFooterComponent={ListFooterComponent}
      {...rest}
    />
  );
};

export { UsersSection as PureUsersSection };

export default createPaginationContainer(
  UsersSection,
  {
    search: graphql`
      fragment UsersSection_search on Search
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "String" }
      ) {
        unifiedNetwork(first: $count, after: $cursor)
          @connection(
            key: "PeopleSelector_UsersSection_unifiedNetwork"
            filters: ["searchTerm"]
          ) {
          totalCount
          edges {
            node {
              id
              ...UsersSectionItem_user
            }
          }
        }
      }
    `,
  },
  {
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        ...props,
        searchTerm: props.searchTerm,
        latitude: props.userLocation?.latitude,
        longitude: props.userLocation?.longitude,
      };
    },
    query: graphql`
      query UsersSectionPaginationQuery(
        $count: Int!
        $cursor: String
        $searchTerm: String
        $latitude: Float
        $longitude: Float
      ) {
        viewer {
          search(
            term: $searchTerm
            longitude: $longitude
            latitude: $latitude
          ) {
            ...UsersSection_search @arguments(count: $count, cursor: $cursor)
          }
        }
      }
    `,
  },
);
