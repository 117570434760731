/* @flow */
import React from 'react';

import styles from './EmptyUserAvatar.module.scss';
import FontAwesomeIcon from '../../../FontAwesomeIcon/FontAwesomeIcon';

const EmptyUserAvatar = () => (
  <div className={styles.container}>
    <FontAwesomeIcon icon={['fas', 'plus']} className={styles.icon} />
  </div>
);

export default EmptyUserAvatar;
