/* @flow */
import React, { useState } from 'react';
import { TouchableOpacity } from '@pluralcom/blueprint';

import FontAwesomeIcon from '../FontAwesomeIcon/FontAwesomeIcon';
import Tooltip from '../Tooltip/Tooltip';

import styles from './TooltipOptions.module.scss';

/**
 * Simple tooltip component, with options list
 *
 *  @example
 *  <TooltipOptions
      {...tooltipProps}
      options={[{
        id: 'test',
        text: `Mark as ${seen_at ? 'Unread' : 'Read'}`,
        onClick: onMarkAsRead,
        iconProps: {
          icon: 'envelope'
        }
      }
    />
 * */

type Props = {
  onVisibleChange?: Function,
  triggerClassName?: string,
  options: Array<{
    id: string,
    text: string,
    iconProps?: {
      icon?: [string, string],
    },
    onClick: any,
  }>,
  renderTrigger?: any,
};

const TooltipOptions = ({ options, onVisibleChange, ...rest }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const onVisibleChangeSpy = (visible) => {
    setIsOpen(visible);
    if (onVisibleChange) {
      onVisibleChange(visible);
    }
  };

  return (
    <Tooltip
      isOpen={isOpen}
      renderTrigger={() => (
        <FontAwesomeIcon icon="ellipsis-h" className={styles['trigger-icon']} />
      )}
      onVisibleChange={onVisibleChangeSpy}
      {...rest}
    >
      <div className={styles.list}>
        {options?.map(({ id, text, iconProps, onClick, ...optionsRest }) => (
          <TouchableOpacity
            key={id}
            id={id}
            className={styles['list-item']}
            onClick={(e) => {
              setIsOpen(false);
              return onClick && onClick(e);
            }}
            {...optionsRest}
          >
            {iconProps && (
              <div className={styles['list-icon-wrapper']}>
                <FontAwesomeIcon
                  className={styles['list-icon']}
                  {...iconProps}
                />
              </div>
            )}
            <span className={styles.text}>{text}</span>
          </TouchableOpacity>
        ))}
      </div>
    </Tooltip>
  );
};

export default TooltipOptions;
