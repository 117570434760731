import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter, useHistory } from 'react-router-dom';
import { useMedia } from 'use-media';
import {
  Col,
  Image,
  MapMarkerUserSearch,
  ModalForm,
  Row,
  Text,
} from '@pluralcom/blueprint';
import { s3Media } from '@pluralcom/plural-js-utils';

import styles from './ModalSearchedSkill.module.scss';
import { withAuthModals } from '../../hocs';
import { responsive } from '../../utils';
import ButtonChoiceCard from '../ButtonChoiceCard';
import ModalReferUser from '../ModalReferUser/ModalReferUser';

interface UserSearchPublicTag {
  /** ID */
  id: string;
  /** Title */
  title: string;
}

interface UserSearchPublicUser {
  /** ID */
  id: string;
  /** First name */
  first_name: string;
}

interface UserSearchPublic {
  /** ID */
  id: string;
  /** Tag */
  tag: UserSearchPublicTag;
  /** User */
  user?: UserSearchPublicUser;
  /** Boolean whether public or not */
  is_public?: boolean;
}

export interface ModalSearchedSkillProps {
  /** Boolean whether modal is open or not */
  isOpen: boolean;
  /** Function to toggle modal */
  toggle: Function;
  /** Boolean whether user is authenticated or not */
  isAuthenticated: boolean;
  /** Function to toggle auth modal */
  toggleAuthModal: Function;
  /** User search public */
  userSearchPublic: UserSearchPublic;
}

/** Constants to easily refer via variables */
const REFER_CHOICE_KEY = 'refer_friend';
const SKILL_CHOICE_KEY = 'add_skill';

/** List of choices to be displayed
 * - key: unique key to identify the choice
 * - title: title of the choice
 * - text: description of the choice
 * - iconProps: icon properties
 *
 *  used for easier mapping of choices
 */
const CHOICES = [
  {
    key: REFER_CHOICE_KEY,
    title: 'Refer a friend',
    text: 'You can earn up to $50 per referral.',
    iconProps: {
      type: 'emoji',
      icon: '🌀',
      size: 'xxxl',
    },
  },
  {
    key: SKILL_CHOICE_KEY,
    title: 'I can do this',
    text: "Add this skill to your profile we'll notify this person.",
    iconProps: {
      type: 'emoji',
      icon: '✋',
      size: 'xxxl',
    },
  },
];

/** Image asset */
const IMAGE_URL = s3Media.getS3MediaUrlWeb(
  'components/ModalSearchedSkill/modal_searched_skill@3x.jpg',
);

/**
 * - Figma v0.0.1
 * - ModalSearchedSkill
 * - Used to display modal to refer to a searched skill.
 */
const ModalSearchedSkill = ({
  isOpen,
  toggle,
  isAuthenticated = false,
  toggleAuthModal,
  userSearchPublic,
}: ModalSearchedSkillProps) => {
  const history = useHistory();
  const [selectedChoice, setSelectedChoice] = useState('');
  const [isOpenReferModal, setisOpenReferModal] = useState(false);

  const { tag, user, is_public } = userSearchPublic || {};
  const { title: searchTerm = '' } = tag || {};
  const { id = '', first_name = 'Somebody' } = user || {};

  let _stickToBreakPoint: 'xs' | 'md' | 'lg' = 'xs';
  const isMinMD = useMedia({ minWidth: responsive.minDeviceWidthMD });
  const isMinLG = useMedia({ minWidth: responsive.minDeviceWidthLG });
  if (isMinLG) {
    _stickToBreakPoint = 'lg';
  } else if (isMinMD) {
    _stickToBreakPoint = 'md';
  }

  /** Method to close the modal & clear selected choice */
  const toggleModal = () => {
    setSelectedChoice('');
    toggle();
  };

  /** Method to open the ReferUser modal */
  const onReferUserClick = () => {
    setisOpenReferModal(true);
  };

  /** Method to be called when selectedChoice exists
   *  Close the modal & clear selectedChoice
   *  - If selected choice is refer_friend, open ReferUser modal
   *  - If selected choice is add_skill, redirect to skill creation page
   */
  const onConfirm = (event) => {
    if (selectedChoice === '') {
      return;
    }
    toggleModal();
    if (!isAuthenticated) {
      toggleAuthModal('Signup', {
        VerifyCode: {
          onSuccess: onReferUserClick,
        },
      })(event);
      return;
    }
    if (selectedChoice === REFER_CHOICE_KEY) {
      onReferUserClick();
    } else if (selectedChoice === SKILL_CHOICE_KEY) {
      history.push(`/skills/create/title?title=${searchTerm}`);
    }
  };

  return (
    <>
      <ModalForm
        isOpen={isOpen}
        toggle={toggleModal}
        modalProps={{ wrapClassName: styles['modal-searched-skill'] }}
        containerProps={{ noPadding: true }}
        className={styles.modal}
        modalBody={
          <Row gx={0}>
            <Col
              gx={0}
              xs={10}
              xsOffset={1}
              md={8}
              mdOffset={2}
              lg={10}
              lgOffset={1}
            >
              <div className={styles.content}>
                <div className={styles['image-container']}>
                  <Image src={IMAGE_URL} className={styles.image} />
                  <MapMarkerUserSearch
                    user={user}
                    title={searchTerm}
                    isPublic={is_public}
                    className={styles['map-marker']}
                  />
                </div>
                <Text
                  element="span"
                  fontSizeType="t2"
                  stickToBreakPoint={_stickToBreakPoint}
                  className={styles.title}
                >{`${first_name} needs a ${searchTerm}`}</Text>
                <div className={styles['choice-container']}>
                  {CHOICES.map((choice) => (
                    <ButtonChoiceCard
                      {...choice}
                      onClick={() => setSelectedChoice(choice.key)}
                      selected={choice.key === selectedChoice}
                    />
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        }
        bottomBarProps={{
          size: 'md',
          paddingType: 'small',
          withElevation: true,
          isBaseVisible: true,
          buttonCount: 2,
          primaryButtonProps: {
            size: 'md',
            text: 'Next',
            disabled: !selectedChoice,
            onClick: onConfirm,
          },
          tertiaryButtonProps: {
            size: 'md',
            text: 'Cancel',
            onClick: toggle,
          },
        }}
      />
      <ModalReferUser
        isOpen={isOpenReferModal}
        toggle={() => setisOpenReferModal(false)}
        userSearchPublic={{
          id: userSearchPublic?.id,
          tag: userSearchPublic?.tag,
          user: { id },
        }}
      />
    </>
  );
};

export { ModalSearchedSkill as PureModalSearchedSkill };

const mapStateToProps = ({ auth: { isAuthenticated } }) => ({
  isAuthenticated,
});

export default compose<React.FunctionComponent>(
  connect(mapStateToProps),
  withRouter,
  withAuthModals(),
)(ModalSearchedSkill);
