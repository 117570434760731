/* @flow */
import React, { useState } from 'react';

import ButtonShareViaSocialNetworks from '../ButtonShareViaSocialNetworks/ButtonShareViaSocialNetworks';
import ModalGetMobileApp from './components/ModalGetMobileApp/ModalGetMobileApp';

type Props = {
  onClick?: Function,
  text?: string,
  disabled?: boolean,
};

const ButtonShareViaPhoneBook = ({ ...rest }: Props) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  return (
    <>
      <ButtonShareViaSocialNetworks
        type="phonebook"
        onClick={() => setIsOpenModal(true)}
        {...rest}
      />
      <ModalGetMobileApp
        isOpen={isOpenModal}
        toggle={() => setIsOpenModal(false)}
      />
    </>
  );
};

export default ButtonShareViaPhoneBook;
