/**
 * @generated SignedSource<<d38e2a28672233bdff87e378b387b6c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SetTourModalInput = {
  clientMutationId?: string | null;
  native?: SetTourModalNativeInput | null;
  web?: SetTourModalWebInput | null;
};
export type SetTourModalWebInput = {
  network_modal?: boolean | null;
  profile_skill_modal?: boolean | null;
};
export type SetTourModalNativeInput = {
  network_modal?: boolean | null;
  profile_skill_modal?: boolean | null;
};
export type SetNetworkTourModalMutation$variables = {
  input: SetTourModalInput;
};
export type SetNetworkTourModalMutation$data = {
  readonly setTourModal: {
    readonly clientMutationId: string | null;
    readonly web: {
      readonly network_modal: {
        readonly id: string;
        readonly value: boolean | null;
      } | null;
    } | null;
  } | null;
};
export type SetNetworkTourModalMutation = {
  response: SetNetworkTourModalMutation$data;
  variables: SetNetworkTourModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Modal",
  "kind": "LinkedField",
  "name": "network_modal",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetNetworkTourModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetTourModalPayload",
        "kind": "LinkedField",
        "name": "setTourModal",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TourModalWeb",
            "kind": "LinkedField",
            "name": "web",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetNetworkTourModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetTourModalPayload",
        "kind": "LinkedField",
        "name": "setTourModal",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TourModalWeb",
            "kind": "LinkedField",
            "name": "web",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "af667736ffd03cfadc202ae983ab02de",
    "id": null,
    "metadata": {},
    "name": "SetNetworkTourModalMutation",
    "operationKind": "mutation",
    "text": "mutation SetNetworkTourModalMutation(\n  $input: SetTourModalInput!\n) {\n  setTourModal(input: $input) {\n    web {\n      network_modal {\n        id\n        value\n      }\n      id\n    }\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "404a118581b9974788f7e5f8a59278b4";

export default node;
