import React from 'react';
import classNames from 'classnames';
import useMedia from 'use-media';
import { BottomBar, Modal, Text } from '@pluralcom/blueprint';

import styles from './ModalComingSoon.module.scss';

export declare interface ModalComingSoonProps
  extends React.ComponentProps<typeof Modal> {
  /** Method to be called on clicking primary button */
  primaryButtonOnClick?: () => void;
}

const ModalComingSoon = ({
  className,
  isOpen,
  toggle,
  primaryButtonOnClick,
  modalProps,
  ...rest
}: ModalComingSoonProps) => {
  let _stickToBreakPoint: 'xs' | 'md' | 'lg' = 'xs';
  const isMinMD = useMedia({ minWidth: '768px' });

  if (isMinMD) {
    _stickToBreakPoint = 'md';
  }
  return (
    <Modal
      className={classNames([styles.container, className])}
      isOpen={isOpen}
      toggle={toggle}
      modalProps={{
        ...modalProps,
        contentClassName: classNames(
          [styles['modal-content']],
          modalProps?.contentClassName,
        ),
        className: classNames([styles['modal-base'], modalProps?.className]),
        backdropClassName: classNames([
          styles['modal-backdrop'],
          modalProps?.backdropClassName,
        ]),
      }}
      {...rest}
    >
      <div className={styles.content}>
        <Text
          element="span"
          fontSizeType="t2"
          stickToBreakpoint={_stickToBreakPoint}
          className={styles.title}
        >
          Coming Soon!
        </Text>

        <Text element="span" fontSizeType="t7" className={styles.body}>
          we’re putting the finishing touches on this feature and can’t wait for
          you to see it. Keep an eye out-we’re almost ready to unveil it!
        </Text>
      </div>

      <BottomBar
        className={classNames([styles['bottom-bar']])}
        size="md"
        paddingType={isMinMD ? 'large' : 'small'}
        isVerticallyStackable={!isMinMD}
        buttonCount={1}
        primaryButtonProps={{
          text: 'Okay',
          uiType: 'primary',
          onClick: () => {
            if (primaryButtonOnClick) {
              primaryButtonOnClick();
            }
            toggle();
          },
        }}
      />
    </Modal>
  );
};

export default ModalComingSoon;
