/* @flow */
import React, { useState } from 'react';
import Files from 'react-files';
import { FILES_ALLOWED_IMAGE_MIMETYPES } from '@pluralcom/plural-js-utils/lib/fileUploadHelpers/fileUploadHelpers';
import { inputsMaxLengths, inputsMinLengths } from '../../assets/data';

import FancyDialog from '../FancyDialog/FancyDialog';

/**
 * Provide component to upload file. Show modal if uploaded file is wrong.
 *  @example
 *    <FileUpload
         filesProps={{
          onChange: files => ...,
          accepts: FILES_ALLOWED_IMAGE_MIMETYPES,
          maxFiles: inputsMaxLengths.skillMedia,
          maxFileSize: inputsMaxLengths.fileSizeBytes,
          minFileSize: inputsMinLengths.fileSizeBytes,
        }}
       errorSizeMessages='...'
       errorTypeMessages='...'
       errorLimitMessages='...'
      >
      ...
     </FileUpload>
 * */

type Props = {
  className?: string,
  classNameContainer: string,
  children?: any,
  filesProps: {
    onChange?: Function,
    onError?: Function,
    accepts?: Array,
    multipleImage?: boolean,
    maxFiles?: number,
    maxFileSize?: number,
    minFileSize?: number,
    clickable?: boolean,
    inputProps?: {
      disabled?: boolean,
    },
  },
  onFileUploadError?: Function,
  errorSizeMessages?: string,
  errorTypeMessages?: string,
  errorLimitMessages?: string,
};

const defaultFilesProps = {
  onChange: undefined,
  onError: undefined,
  accepts: FILES_ALLOWED_IMAGE_MIMETYPES,
  multipleImage: true,
  maxFiles: Infinity,
  maxFileSize: inputsMaxLengths.fileSizeBytes,
  minFileSize: inputsMinLengths.fileSizeBytes,
  clickable: true,
};

const toMB = (bytes) => bytes / 1000000;
const FILE_SIZE_ERROR_KEY = 'size';
const FILE_TYPE_ERROR_KEY = 'type';
const FILE_LIMIT_ERROR_KEY = 'limit';
const FILE_SIZE_MESSAGE =
  'The file you are try to upload is too large. You can only upload files up to %size% MB in size.';
const FILE_TYPE_MESSAGE = 'This file type cannot be upload';
const FILE_LIMIT_MESSAGE = 'You reached the maximum number of file to upload.';

const FileUpload = ({
  filesProps = {},
  children,
  classNameContainer,
  onFileUploadError,
  errorSizeMessages = FILE_SIZE_MESSAGE,
  errorTypeMessages = FILE_TYPE_MESSAGE,
  errorLimitMessages = FILE_LIMIT_MESSAGE,
  ...rest
}: Props) => {
  const maxFileSize = filesProps.maxFileSize || defaultFilesProps.maxFileSize;
  const [fileError, setFileError] = useState('');

  const onFileUploadErrorSpy = (error) => {
    if (error.code === 1) {
      setFileError(FILE_TYPE_ERROR_KEY);
    }
    if (error.code === 2) {
      setFileError(FILE_SIZE_ERROR_KEY);
    }
    if (error.code === 4) {
      setFileError(FILE_LIMIT_ERROR_KEY);
    }

    return onFileUploadError && onFileUploadError(error);
  };

  const messageMap = {
    [FILE_SIZE_ERROR_KEY]: errorSizeMessages.replace(
      '%size%',
      toMB(maxFileSize),
    ),
    [FILE_TYPE_ERROR_KEY]: errorTypeMessages,
    [FILE_LIMIT_ERROR_KEY]: errorLimitMessages,
  };

  return (
    <div className={classNameContainer}>
      <Files
        {...{
          ...defaultFilesProps,
          ...filesProps,
        }}
        onError={onFileUploadErrorSpy}
        {...rest}
      >
        {children}
      </Files>

      <FancyDialog
        isOpen={Boolean(fileError)}
        toggle={() => setFileError('')}
        title="Oops! 😅"
        bodyText={messageMap[fileError] || ''}
        primaryBtnText={fileError === 'size' ? 'Got it!' : 'Okay'}
        primaryBtnOnClick={() => setFileError('')}
      />
    </div>
  );
};

export default FileUpload;
