/* @flow */
import React from 'react';
import classNames from 'classnames';
import fontawesome from '@fortawesome/fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon as FontAwesomeIconRaw } from '@fortawesome/react-fontawesome';

import '@fortawesome/fontawesome/styles.css';

import libraryIcons from './libraryIcons';

type Props = {
  icon: string | [string, string],
  className?: ?string,
};

fontawesome.config = {
  autoAddCss: false,
};

library.add(...libraryIcons);

/**
 * FontAwesomeIcon wrapper
 *
 * - Wraps the raw FontAwesomeIcon components and only adds the icons used by plural to the library
 * - Library Icons can be found in `./libraryIcons.js`
 */
const FontAwesomeIcon = ({ icon, className, ...rest }: Props) => (
  <FontAwesomeIconRaw
    icon={icon}
    className={classNames(className)}
    role="presentation"
    {...rest}
  />
);

export default FontAwesomeIcon;
