/* @flow */

import React, { useState } from 'react';
import { useMedia } from 'use-media';
import classNames from 'classnames';
import { ModalConfirmation } from '@pluralcom/blueprint';

import TooltipOptions from '../../../TooltipOptions/TooltipOptions';

import SkillCardMiniFloatingButtonStyles from '../SkillCardMiniFloatingButton/SkillCardMiniFloatingButton.module.scss';
import styles from './EditButton.module.scss';
import { innerRoutesHelpers, responsive } from '../../../../utils';
import SkillEditButton from '../../../EditButton/EditButton';
import { DeleteSkillMutation } from '../../../../graphql/mutations';

type Props = {
  skill: {
    id: string,
    user: {
      id: string,
    },
  },
  parentID: string,
  totalCount: number,
  size?: 'xs' | 'lg',
};

const EditButton = ({ skill, parentID, totalCount, size, ...rest }: Props) => {
  const [isOpenDialogDeleteSkill, setIsOpenDialogDeleteSkill] = useState(false);
  const toggleDialogDeleteSkill = () =>
    setIsOpenDialogDeleteSkill((val) => !val);
  const isLg = useMedia({ minWidth: responsive.minDeviceWidthLG });
  const screen_size = size ?? (!isLg ? 'xs' : 'lg');

  return (
    <div
      className={classNames([
        SkillCardMiniFloatingButtonStyles.container,
        SkillCardMiniFloatingButtonStyles[`container-${screen_size}`],
      ])}
    >
      <TooltipOptions
        renderTrigger={() => (
          <SkillEditButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            size={screen_size}
            className={styles.button}
            {...rest}
          />
        )}
        options={[
          {
            id: 'edit',
            text: 'Edit',
            to: {
              pathname: innerRoutesHelpers.getEditSkillLink(skill),
              state: {
                isEditFullPath: true,
              },
            },
            iconProps: {
              icon: 'edit',
            },
          },
          {
            id: 'delete',
            text: 'Delete',
            onClick: (e) => {
              e.preventDefault();
              e.stopPropagation();
              toggleDialogDeleteSkill();
            },
            iconProps: {
              icon: 'trash',
            },
          },
        ]}
      />
      <ModalConfirmation
        isOpen={isOpenDialogDeleteSkill}
        toggle={toggleDialogDeleteSkill}
        onConfirm={() => {
          toggleDialogDeleteSkill();
          DeleteSkillMutation(skill.id, {
            parentID: parentID || skill?.user?.id,
            totalCount,
          });
        }}
      />
    </div>
  );
};

export default EditButton;
