/* @flow */
import React from 'react';
import { connect } from 'react-redux';

type Props = {
  isAuthenticated: boolean,
  children: Function,
};

const Authenticated = ({ isAuthenticated, children }: Props) => (
  <>{children ? children(isAuthenticated) : null}</>
);

const mapStateToProps = ({ auth: { isAuthenticated } }) => ({
  isAuthenticated,
});

export default connect(mapStateToProps)(Authenticated);
