/* @flow */

import React from 'react';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';

import { SkillMediaGalleryMini } from './components';

import type { SkillCardMiniMediaSection_skill } from './__generated__/SkillCardMiniMediaSection_skill.graphql';

export type Props = {
  isOwner?: boolean,
  hasImage?: boolean,
  skill: SkillCardMiniMediaSection_skill,
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl',
};

const SkillCardMiniMediaSection = ({ skill, ...rest }: Props) => {
  const { media, user } = skill;

  const hasImage = media && media.edges?.length > 0;

  return (
    <SkillMediaGalleryMini
      media={media}
      user={user}
      hasImage={hasImage}
      {...rest}
    />
  );
};

export default createFragmentContainer(SkillCardMiniMediaSection, {
  skill: graphql`
    fragment SkillCardMiniMediaSection_skill on Skill {
      id
      user {
        id
        profile_color
      }
      media(first: 50) {
        edges {
          node {
            id
            image {
              id
              smallThumbnail
              medium_wide
            }
            video {
              id
              url
            }
          }
        }
      }
    }
  `,
});
