/* @flow */

import graphql from 'babel-plugin-relay/macro';
import { commitMutation } from '../..';

export default (id, value: boolean): Promise<Object> =>
  new Promise((resolve, reject) => {
    const mutation = graphql`
      mutation SetNetworkTourModalMutation($input: SetTourModalInput!) {
        setTourModal(input: $input) {
          web {
            network_modal {
              id
              value
            }
          }
          clientMutationId
        }
      }
    `;

    const variables = {
      input: {
        web: {
          network_modal: value,
        },
      },
    };

    const optimisticResponse = {
      setTourModal: {
        web: {
          network_modal: {
            id,
            value,
          },
        },
      },
    };

    commitMutation({
      mutation,
      variables,
      optimisticResponse,
      onCompleted: resolve,
      onError: err => reject(err),
    });
  });
