/** @flow */
import React, { Component } from 'react';
import Auth from '../../services/Auth/Auth';
import { errorTexts } from '../../assets/data';
import { fbLogin, fbLogout } from '../../utils/facebookHelpers/facebookHelpers';

const withFacebook = (
  {
    /** If true: Triggeres refetch when accessToken is retrieved */
    triggerRelayRefresh,
    disableRelayRefetch,
  }: {
    triggerRelayRefresh: boolean,
    disableRelayRefetch: boolean,
  } = { triggerRelayRefresh: false, disableRelayRefetch: false },
) => (WrappedComponent) =>
  class extends Component<
    *,
    {
      triggerRelayRefresh: boolean,
      relay: RelayType,
    },
    *,
  > {
    constructor(props) {
      super(props);
      this.state = {
        /** Any facebook server error response */
        serverError: null,
        /** If a facebook method is processing */
        fbLoading: false,
        /** Whether the current user is logged in */
        fbLoggedIn: false,
        /** FB access token */
        fbAccessToken: null,
        /** FB success message (if one is returned) */
        fbSuccess: null,
        /**
         * If an FB acc is already connected to another Pl acc
         * and you click 'see friends' on plural, we'll let the
         * wrapped component know.
         * */
        fbLoggedInClientOnly: false,
        fbUserName: '',
      };
    }

    componentDidMount() {
      if (!window.FB || !window.location.origin?.startsWith('https')) {
        return;
      }
      this._getFbLoginStatus();
    }

    _setFbLoading = (fbLoading: boolean) => this.setState({ fbLoading });

    /**
     * Handles the facebook login triggers a refetch IF
     * configured via the triggerRefetch arg.
     * fbLoading = true whilst logging in
     */
    _handleLogin = ({
      triggerRelayRefresh: triggerRelayRefreshAction,
      logoutIfLoggedIn,
      withAuth,
    }: {
      triggerRelayRefresh: boolean,
      logoutIfLoggedIn: boolean,
      withAuth: boolean,
    } = {}): Promise<Object> =>
      // eslint-disable-next-line no-async-promise-executor
      new Promise(async (resolve) => {
        const { fbLoggedIn } = this.state;
        /**
         * In the rare case where you try to run fb.login() when
         * the facebook SDK is already logged in, log the FB session out
         * and try again.
         */
        if (fbLoggedIn && logoutIfLoggedIn) {
          await fbLogout();
        } else if (fbLoggedIn) {
          return this._refetch();
        }

        this._setFbLoading(true);
        return fbLogin(({ authResponse }) => {
          const { accessToken } = authResponse || {};
          if (accessToken) {
            // Login here
            Auth.connectFacebook(accessToken, withAuth).then(
              ({
                success: fbSuccess,
                signinFacebook: { profile, error: serverErrorRes },
              }) => {
                this.setState({
                  fbSuccess,
                  serverError: serverErrorRes,
                  fbLoading: false,
                  fbLoggedIn: fbSuccess,
                  fbLoggedInClientOnly: !fbSuccess && accessToken,
                });

                if (triggerRelayRefreshAction && !serverErrorRes) {
                  this._refetch();
                }

                resolve(profile);
              },
            );
          } else {
            /**
             * When no accessToken is returned from the FB.login
             * method.
             */
            this._setFbLoading(false);
            this.setState({
              serverError: `${errorTexts.facebookErrorEncountered} ${errorTexts.pleaseTryAgain}`,
            });
          }
        });
      });

    /**
     * Refetch with the current accessToken.
     * props.relay.isLoading = true whilst the refetch is in progresss
     */
    _refetch = () => {
      const { fbAccessToken } = this.state;
      const { relay } = this.props;
      if (relay.refetch && !disableRelayRefetch) {
        relay.refetch((oldVariables) => ({
          ...oldVariables,
          accessToken: fbAccessToken,
        }));
      }
      // @todo add other refetches? E.g pagination container?
    };

    /**
     * Get accessToken and refetch
     */
    _getAccessTokenAndRefetch = () => {
      if (!window.FB || !window.location.origin?.startsWith('https')) {
        return;
      }
      window.FB.getLoginStatus((response) => {
        const {
          authResponse: { accessToken },
        } = response;
        this.setState(
          {
            fbAccessToken: accessToken,
          },
          () => {
            this._refetch();
          },
        );
      });
    };

    _setFbLoggedIn = (fbLoggedIn: boolean) => {
      let state = {};

      /**
       * If `false` reset state
       */
      if (!fbLoggedIn) {
        state = {
          fbLoggedIn: false,
          fbAccessToken: null,
          fbSuccess: null,
        };
      }

      this.setState({
        fbLoggedIn,
        fbLoading: false,
        ...state,
      });
    };

    _getFbLoginStatus = () => {
      if (!window.FB || !window.location.origin?.startsWith('https')) {
        return;
      }

      this._setFbLoading(true);
      window.FB.getLoginStatus((response) => {
        const { status } = response;
        if (status === 'connected') {
          const {
            authResponse: { accessToken },
          } = response;

          window.FB.api('/me', (res) => {
            /** Set accessToken and userName */
            this.setState(
              {
                fbAccessToken: accessToken,
                fbUserName: res.name,
              },
              () => {
                /** Set fbLoggedIn */
                this._setFbLoggedIn(true);
                /** Refetch once accessToken is retrieved (if configured) */
                if (triggerRelayRefresh) {
                  this._refetch();
                }
              },
            );
          });
        } else {
          this._setFbLoggedIn(false);
        }
      });
    };

    render() {
      const {
        fbLoading,
        fbLoggedIn,
        fbAccessToken,
        fbSuccess,
        fbLoggedInClientOnly,
        serverError,
        fbUserName,
      } = this.state;
      const { relay: { isLoading } = {} } = this.props;

      return (
        <WrappedComponent
          getAccessTokenAndRefetch={this._getAccessTokenAndRefetch}
          refetch={this._refetch}
          handleLogin={this._handleLogin}
          fbLoading={fbLoading}
          fbLoggedIn={fbLoggedIn}
          fbAccessToken={fbAccessToken}
          fbUserName={fbUserName}
          fbSuccess={fbSuccess}
          isLoading={isLoading}
          fbLoggedInClientOnly={fbLoggedInClientOnly}
          fbAuthError={serverError}
          {...this.props}
        />
      );
    }
  };

export default withFacebook;
