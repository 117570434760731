/* @flow */
import React, { lazy, Suspense } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { RelayEnvironmentProvider } from 'react-relay';

import { Provider } from 'react-redux';
import {
  localStorageService,
  sessionStorageService,
} from '@pluralcom/plural-web-utils';

import { getEnvironment } from './graphql/Environment';
import reduxStore from './redux/store';
import { RandomSeed } from './services';
import Auth from './services/Auth/Auth';
import {
  ErrorBoundary,
  SiteWrapper,
  ProtectedRoute,
  LoadingLayout,
} from './components';
import {
  sentryHelpers,
  initStripe,
  browserHistory,
  lazyLoadRetry,
  branchLinking,
} from './utils';

import routes from './routes';

import './App.scss';

const Landing = lazy(() =>
  lazyLoadRetry(() => import('./screens/Landing/Landing')),
);
const Info = lazy(() => lazyLoadRetry(() => import('./screens/Info/Info')));
const Signup = lazy(() =>
  lazyLoadRetry(() => import('./screens/Signup/Signup')),
);
const Login = lazy(() => lazyLoadRetry(() => import('./screens/Login/Login')));
const VerifyCode = lazy(() =>
  lazyLoadRetry(() => import('./screens/VerifyCode/VerifyCode')),
);
const Notifications = lazy(() =>
  lazyLoadRetry(() => import('./screens/Notifications/Notifications')),
);
const CommunityActivities = lazy(() =>
  lazyLoadRetry(() =>
    import('./screens/CommunityActivities/CommunityActivities'),
  ),
);
const Messenger = lazy(() =>
  lazyLoadRetry(() => import('./screens/Messenger/Messenger')),
);
const Search = lazy(() =>
  lazyLoadRetry(() => import('./screens/Search/Search')),
);
const Profile = lazy(() =>
  lazyLoadRetry(() => import('./screens/Profile/Profile')),
);
const Settings = lazy(() =>
  lazyLoadRetry(() => import('./screens/Settings/Settings')),
);
const CreateSkill = lazy(() =>
  lazyLoadRetry(() => import('./screens/CreateSkill/CreateSkill')),
);
const EditSkill = lazy(() =>
  lazyLoadRetry(() => import('./screens/EditSkill/EditSkill')),
);
const CreateReview = lazy(() =>
  lazyLoadRetry(() => import('./screens/CreateReview/CreateReview')),
);
const ViewReview = lazy(() =>
  lazyLoadRetry(() => import('./screens/ViewReview/ViewReview')),
);
const AddEditLocation = lazy(() =>
  lazyLoadRetry(() => import('./screens/AddEditLocation/AddEditLocation')),
);
const ExperiencePosition = lazy(() =>
  lazyLoadRetry(() =>
    import('./screens/ExperiencePosition/ExperiencePosition'),
  ),
);
const ExperienceDescription = lazy(() =>
  lazyLoadRetry(() =>
    import('./screens/ExperienceDescription/ExperienceDescription'),
  ),
);
const PayoutMethod = lazy(() =>
  lazyLoadRetry(() => import('./screens/PayoutMethod/PayoutMethod')),
);
const Payments = lazy(() =>
  lazyLoadRetry(() => import('./screens/Payments/Payments')),
);
const Unsubscribe = lazy(() =>
  lazyLoadRetry(() => import('./screens/Subscription/Unsubscribe/Unsubscribe')),
);
const Subscribe = lazy(() =>
  lazyLoadRetry(() => import('./screens/Subscription/Subscribe/Subscribe')),
);
const Unauthorized = lazy(() =>
  lazyLoadRetry(() => import('./screens/Errors/Unauthorized/Unauthorized')),
);
const NotFound = lazy(() =>
  lazyLoadRetry(() => import('./screens/Errors/NotFound/NotFound')),
);
const Playground = lazy(() =>
  lazyLoadRetry(() => import('./screens/Playground/Playground')),
);
const AnswerFaq = lazy(() =>
  lazyLoadRetry(() => import('./screens/AnswerFaq/AnswerFaq')),
);
const EditFaq = lazy(() =>
  lazyLoadRetry(() => import('./screens/EditFaq/EditFaq')),
);
const ExternalNotifications = lazy(() =>
  lazyLoadRetry(() => import('./screens/ExternalNotifications/ExternalNotifications')),
);
const CreateSkillOffer = lazy(() =>
  lazyLoadRetry(() => import('./screens/CreateSkillOffer/CreateSkillOffer')),
);
const EditSkillOffer = lazy(() =>
  lazyLoadRetry(() => import('./screens/EditSkillOffer/EditSkillOffer')),
);
const CookiesSettings = lazy(() =>
  lazyLoadRetry(() => import('./screens/CookiesSettings/CookiesSettings')),
);
const Room = lazy(() => lazyLoadRetry(() => import('./screens/Room/Room')));
const Referrals = lazy(() =>
  lazyLoadRetry(() => import('./screens/Referrals/Referrals')),
);
const ReferralsCreate = lazy(() =>
  lazyLoadRetry(() => import('./screens/ReferralsCreate/ReferralsCreate')),
);
const Deal = lazy(() => lazyLoadRetry(() => import('./screens/Deal/Deal')));

/**
 * Map of lazily loaded components
 *
 * - Note that the paths have to be hardcoded as per webpack rules
 */
const LOADED_COMPONENTS = {
  Landing,
  Info,
  Signup,
  Login,
  VerifyCode,
  Notifications,
  CommunityActivities,
  Messenger,
  Search,
  Profile,
  Settings,
  CreateSkill,
  EditSkill,
  CreateReview,
  ViewReview,
  AddEditLocation,
  ExperiencePosition,
  ExperienceDescription,
  PayoutMethod,
  Payments,
  Subscribe,
  Unsubscribe,
  Unauthorized,
  NotFound,
  Playground,
  AnswerFaq,
  EditFaq,
  ExternalNotifications,
  CreateSkillOffer,
  EditSkillOffer,
  Room,
  Deal,
  CookiesSettings,
  Referrals,
  ReferralsCreate,
};

Auth.init();
localStorageService.setPrefix('pl_');
sessionStorageService.setPrefix('pl_');
sentryHelpers.init();
initStripe();
RandomSeed.init();
branchLinking.init();

let _renderedRoutes;
/** Renders the routes based on the route config */
const _renderRoutes = () => {
  _renderedRoutes = routes[0].routes.map((routeObj, i) => {
    const {
      path,
      exact,
      componentStr,
      redirectProps,
      _opts = {},
      ...rest
    } = routeObj;

    /** Generate Route component */
    const RouteComponent =
      _opts?.privacy === 'protected' ? ProtectedRoute : Route;

    return (
      <RouteComponent
        // eslint-disable-next-line react/no-array-index-key
        key={`${path}_${exact}_${redirectProps ? 'redirect_' : ''}${i}`}
        path={path}
        exact={exact}
        component={LOADED_COMPONENTS[componentStr]}
        render={
          redirectProps ? () => <Redirect {...redirectProps} /> : undefined
        }
        {...rest}
      />
    );
  });
  return _renderedRoutes;
};

const App = () => (
  <ErrorBoundary>
    <RelayEnvironmentProvider environment={getEnvironment()}>
      <Provider store={reduxStore}>
        <Suspense fallback={<LoadingLayout />}>
          <Router history={browserHistory}>
            <SiteWrapper>
              <Switch>{_renderedRoutes || _renderRoutes()}</Switch>
            </SiteWrapper>
          </Router>
        </Suspense>
      </Provider>
    </RelayEnvironmentProvider>
  </ErrorBoundary>
);

export default App;
