/* @flow */
import React from 'react';
import classNames from 'classnames';

import Spinner from '../../Spinner/Spinner';

import ListItem from '../ListItem/ListItem';

import styles from './SearchingListItem.module.scss';

const SearchingListItem = ({
  className,
  text = 'Searching...',
}: {
  className?: string,
  text?: string,
}) => (
  <ListItem className={classNames(styles.container, className)}>
    <Spinner className={styles.spinner} />
    {text && <span className={styles.title}>{text}</span>}
  </ListItem>
);

export default SearchingListItem;
