/* @flow */
import React from 'react';
import UserAvatarBase from '../../../UserAvatar/UserAvatar';
import styles from './UserAvatar.module.scss';

type Props = {};

const UserAvatar = ({ ...rest }: Props) => (
  <UserAvatarBase
    className={styles.container}
    type="smallThumbnail"
    textSize="smallThumbnail"
    {...rest}
  />
);

export default UserAvatar;
