/* @flow */
import React from 'react';
import classNames from 'classnames';

import styles from './Scrollable.module.scss';

type Props = {
  children: React$Node,
  className: string,
  scrollThreshold?: number,
  onScrollEndReached?: Function,
  onScroll?: Function,
};

/**
 * Provide simple autosuggest list component for input
 * */
const Scrollable = ({
  children,
  className,
  scrollThreshold = 50,
  onScrollEndReached,
  onScroll,
}: Props) => {
  /** Handle scroll event */
  function onScrollSpy(e) {
    const element = e?.target || {};
    const distanceToThreshold =
      element.scrollHeight -
      element.scrollTop -
      element.clientHeight -
      scrollThreshold;
    if (onScrollEndReached && distanceToThreshold <= 0) {
      onScrollEndReached(e);
    }
    if (onScroll) {
      onScroll(e);
    }
  }

  return (
    <div
      className={classNames(styles.container, className)}
      onScroll={onScrollSpy}
    >
      {children}
    </div>
  );
};

export default Scrollable;
