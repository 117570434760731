/* @flow */

import branch from 'branch-sdk';

import localStorageHelpers from '../localStorageHelpers';
import sentryHelpers from '../sentryHelpers/sentryHelpers';
import logger from '../logger/logger';

import config from './config';
import handeBranchLinking from './handeBranchLinking';

/**
 * Initialize branch
 */
const init = () => {
  if (window.navigator.userAgent === 'ReactSnap') {
    return;
  }
  branch.init(config.key, {}, (err, data) => {
    if (err) {
      logger.debugError('Error: Branch.io:', err);
      sentryHelpers.addBreadcrumb({
        level: 'warning',
        category: 'branchio_error',
        data: err,
      });
      sentryHelpers.captureException(err);
      return;
    }
    const user = localStorageHelpers.getUser();
    if (user?.id) {
      branch.setIdentity(user.id, (error) => {
        if (error) {
          logger.debugError('Error: Branch.io: setting identity', error);
        }
      });
    }
    handeBranchLinking(data);
  });
};

export { init };
