import React from 'react';
import classNames from 'classnames';
import {
  ButtonLink,
  ButtonFlat,
  Avatar,
  AvatarDiagonalOverlay,
} from '@pluralcom/blueprint';

import ListItemUserInteractionUserName from './components/ListItemUserInteractionUserName/ListItemUserInteractionUserName';
import ListItemUserInteractionTimeAgo from './components/ListItemUserInteractionTimeAgo/ListItemUserInteractionTimeAgo';

import styles from './ListItemUserInteraction.module.scss';

interface ListItemUserInteractionProps {
  /** users */
  users?: Array<{
    /** user id */
    id: string;
    /** user first name */
    first_name: string;
    /** avatar */
    avatar?: {
      /** avatar id */
      id: string;
      /** smallThumbnail */
      smallThumbnail?: string | null;
      /** thumbnail */
      thumbnail?: string | null;
    } | null;
  }>;
  /** body text */
  bodyText?: string;
  /** date time */
  dateTime?: string;
  /** custom className */
  className?: string;
  /** button Props */
  buttonProps?: React.ComponentProps<typeof ButtonFlat>;
  /** whether or not to render in mini form */
  mini?: boolean;
  /** avatarProps */
  avatarProps?: React.ComponentProps<typeof Avatar>;
  /** children to render within the component */
  children?: React.ReactNode;
}

const ListItemUserInteraction = ({
  className,
  users = [],
  bodyText,
  dateTime,
  buttonProps,
  mini,
  children,
  avatarProps,
}: ListItemUserInteractionProps) => (
  <ButtonLink
    className={classNames([
      styles.container,
      { [styles['container--mini']]: mini },
      className,
    ])}
    fallbackToDiv
  >
    <div className={styles['container-inner']}>
      {users?.length > 1 ? (
        <AvatarDiagonalOverlay className={styles.image} users={users} size="sm" {...avatarProps} />
      ) : null}
      {users?.length === 1 || avatarProps ? (
        <Avatar className={styles.image} user={users[0]} size="md" {...avatarProps} />
      ) : null}
      <div
        className={classNames([
          styles['content-right'],
          { [styles['content-right--mini']]: mini },
        ])}
      >
        {mini ? (
          <div className={styles['content-top']}>
            <ListItemUserInteractionUserName users={users} mini={mini} />
            {dateTime ? (
              <ListItemUserInteractionTimeAgo dateTime={dateTime} />
            ) : undefined}
          </div>
        ) : null}
        {mini ? null : <ListItemUserInteractionUserName users={users} />}
        <div className={styles['content-right-main']}>
          {bodyText ? <p className={styles['body-text']}>{bodyText}</p> : null}
          {buttonProps ? (
            <ButtonFlat
              size="sm"
              uiType="primary"
              {...buttonProps}
              className={classNames([styles.button, buttonProps.className])}
            />
          ) : null}
        </div>
        {mini || !dateTime ? null : (
          <ListItemUserInteractionTimeAgo dateTime={dateTime} />
        )}
      </div>
    </div>
    {children}
  </ButtonLink>
)

export default ListItemUserInteraction;
