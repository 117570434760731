/* @flow */
import React from 'react';
import classNames from 'classnames';

import Scrollable from '../../../Scrollable/Scrollable';
import Loader from './components/Loader/Loader';

import { highlightSearchTerm } from '../../../../utils';
import styles from './AutosuggestList.module.scss';

type Props = {
  autocompleteSearches?: [
    {
      id: string,
      title: string,
    },
  ],
  isLoading: boolean,
  isOpen: boolean,
  searchTerm?: string,
  onSelect: Function,
  className?: string,
  scroll?: {
    onScrollEndReached?: Function,
    isLoadingMore?: string,
  },
  style?: Object,
  renderLeftItemComponent: Function,
};

const AutosuggestList = ({
  searchTerm = '',
  isOpen,
  isLoading,
  autocompleteSearches,
  onSelect,
  scroll = {},
  className,
  renderLeftItemComponent,
  style,
}: Props) => {
  const { onScrollEndReached, isLoadingMore = false } = scroll;
  const onItemClick = (item) => () => onSelect && onSelect(item);

  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Scrollable
        className={classNames(styles['list-container'], className)}
        onScrollEndReached={onScrollEndReached}
        style={style}
      >
        <div className={styles.list}>
          {!isLoading &&
            autocompleteSearches.map((item) => (
              <div
                role="presentation"
                key={item.id}
                className={classNames(styles['list-item'])}
                onClick={onItemClick(item)}
                onKeyDown={onItemClick(item)}
              >
                {renderLeftItemComponent && renderLeftItemComponent(item)}
                <p className={classNames(styles['list-item-text'])}>
                  {highlightSearchTerm(item.title, searchTerm).before}
                  <span className={styles['regular-text']}>
                    {highlightSearchTerm(item.title, searchTerm).highlight}
                  </span>
                  {highlightSearchTerm(item.title, searchTerm).after}
                </p>
              </div>
            ))}
          <Loader isLoading={isLoading || isLoadingMore} />
        </div>
      </Scrollable>
    </div>
  );
};

export default AutosuggestList;
