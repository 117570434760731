/* @flow */
import React from 'react';
import classNames from 'classnames';
import { TouchableOpacity } from '@pluralcom/blueprint';

import FontAwesomeIcon from '../FontAwesomeIcon/FontAwesomeIcon';
import styles from './ButtonShareViaSocialNetworks.module.scss';

type Props = {
  onClick?: Function,
  type:
    | 'facebook'
    | 'messenger'
    | 'google'
    | 'linkedin'
    | 'phonebook'
    | 'email'
    | 'copy',
  text?: string,
  disabled?: boolean,
  className?: string,
  isLoading?: boolean,
  children?: React$Node,
};

const iconsMap = {
  facebook: ['fab', 'facebook-square'],
  google: ['fab', 'google'],
  linkedin: ['fab', 'linkedin'],
  phonebook: ['fas', 'address-book'],
  copy: 'link',
  email: ['fas', 'envelope'],
  messenger: ['fab', 'facebook-messenger'],
  twitter: ['fab', 'twitter'],
  apple: ['fab', 'apple'],
};

const ButtonShareViaSocialNetworks = ({
  onClick,
  type,
  text,
  disabled,
  className,
  isLoading,
  children,
  ...rest
}: Props) => (
  <TouchableOpacity
    className={classNames(
      styles.button,
      styles[`btn-${type}`],
      disabled && styles['btn-disabled'],
      className,
    )}
    onClick={onClick}
    disabled={disabled}
    {...rest}
  >
    {isLoading ? (
      <FontAwesomeIcon
        icon={['fas', 'spinner']}
        className={styles['button-icon']}
        pulse
        scale="2x"
      />
    ) : (
      <FontAwesomeIcon
        icon={iconsMap[type]}
        className={styles['button-icon']}
      />
    )}
    {text || children}
  </TouchableOpacity>
);

export default ButtonShareViaSocialNetworks;
