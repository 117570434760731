/* @flow */
import React, {
  useEffect,
  useState,
  useCallback,
  useImperativeHandle,
} from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';
import { InputText, Form, FormGroup } from '@pluralcom/blueprint';

import { inputsMaxLengths } from '../../assets/data';

import { ReferUserMutation } from '../../graphql/mutations';

import InputSkill from '../InputSkill/InputSkill';
import FancyDialog from '../FancyDialog/FancyDialog';
import PeopleSelectorInput from '../PeopleSelectorInput/PeopleSelectorInput';

import styles from './FormReferUser.module.scss';

/**
 * Provide modal for people selector list
 * */

type Props = {
  viewer: Object,
  otherUserId?: string | null,
  userSearchPublic?: {
    id: string,
    user: {
      id: string,
    },
    tag: {
      id: string,
      title: string,
    },
  },
  /** called on form validation change */
  onValidationChange?: () => boolean,
};

const FormReferUser = React.forwardRef(
  (
    {
      viewer: inViewer,
      otherUserId = null,
      userSearchPublic,
      onValidationChange,
    }: Props,
    ref,
  ) => {
    const [referredUserTag, setReferredUserTag] = useState(null);
    const [selectedSkill, setSelectedSkill] = useState(null);
    const [note, setNote] = useState('');
    const [serverError, setServerError] = useState(null);

    const viewer = useFragment(
      graphql`
        fragment FormReferUser_viewer on ViewerType
        @argumentDefinitions(
          otherUserId: { type: "String", defaultValue: "" }
          skipOwnSkills: { type: "Boolean", defaultValue: false }
        ) {
          referrals {
            id
            totalEarned
            totalEarnableReferrals
          }
          ...PeopleSelectorInput_viewer
          ...InputSkill_viewer
            @arguments(
              other_user_id: $otherUserId
              skipOwnSkills: $skipOwnSkills
            )
        }
      `,
      inViewer || null,
    );

    /** Reset selected skill when user id changes */
    useEffect(() => {
      if (
        selectedSkill?.user &&
        referredUserTag?.id !== selectedSkill?.user?.id
      ) {
        setSelectedSkill(null);
      }
    }, [referredUserTag, selectedSkill]);

    useImperativeHandle(ref, () => ({
      /** submit form - call ReferUserMutation */
      submit: async () => {
        try {
          const result = await ReferUserMutation(
            {
              referred_user_id: referredUserTag?.id,
              referred_email: referredUserTag?.id
                ? undefined
                : referredUserTag?.email,
              referred_phone: referredUserTag?.id
                ? undefined
                : referredUserTag?.phone,
              referred_to_user_id: userSearchPublic?.user?.id,
              user_search_public_id: userSearchPublic?.id,
              skill_id: selectedSkill?.id,
              skill_title: selectedSkill?.id ? undefined : selectedSkill?.title,
              note,
            },
            {
              referrals: viewer?.referrals,
              referred: referredUserTag,
              referred_to: userSearchPublic?.user,
            },
          );
          const { error } = result.referUser;
          if (error) {
            setServerError(error?.message || error.toString());
          }
        } catch (error) {
          setServerError(error?.message || error.toString());
        }
      },
    }));

    /**
     * handle change of skill input
     */
    const handleChangeInputSkill = useCallback(
      (skill) => setSelectedSkill(skill),
      [],
    );

    return (
      <div>
        <h2 className={styles.header}>
          <span role="img" aria-hidden>
            📣
          </span>
          New Referral
        </h2>
        <Form>
          <FormGroup>
            <PeopleSelectorInput
              autoFocus
              dropdown
              className={styles['form-element']}
              viewer={viewer}
              onChangeTags={(tags) => {
                const person = tags[0];
                setReferredUserTag(person);
                onValidationChange?.(
                  Boolean(person?.id || person?.email || person?.phone),
                );
              }}
            />
          </FormGroup>
          <FormGroup>
            <InputSkill
              className={styles['form-element']}
              viewer={viewer}
              otherUserId={referredUserTag?.id || otherUserId || ''}
              selectedSkill={selectedSkill}
              onSelectAutosuggest={handleChangeInputSkill}
              placeholder="Select skill (optional)"
              skipOwnSkills
              skillTitleToPreselect={userSearchPublic?.tag?.title}
              initialValue={userSearchPublic?.tag?.title || ''}
            />
          </FormGroup>
          <FormGroup>
            <InputText
              multiline
              placeholder="Why do you recommend working with this person? (optional)"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              maxLength={inputsMaxLengths.referralNote}
            />
          </FormGroup>
        </Form>
        {/* @todo - replace with new modals */}
        <FancyDialog
          toggle={() => setServerError(null)}
          isOpen={serverError}
          title="Oops!"
          bodyText={serverError}
          primaryBtnText="Got it!"
          primaryBtnOnClick={() => setServerError(null)}
        />
      </div>
    );
  },
);

const FormUserWithSuspense = (props) => (
  <React.Suspense fallback={<FormReferUser {...props} />}>
    <FormReferUser {...props} />
  </React.Suspense>
);

export default FormReferUser;
