/* @flow */
import React from 'react';
import classNames from 'classnames';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { COMMUNITY_ACTIVITY_TYPES } from '@pluralcom/plural-js-utils/lib/activityHelpers/constants';
import useMedia from 'use-media';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { toggleSearchPublicModal as toggleSearchPublicModalAction } from '../../../../redux/reducers/modalsReducer/modalsReducer';
import { responsive } from '../../../../utils';
import PluralMap from '../../../Maps/PluralMap/PluralMap';
import MapMarker from '../../../Maps/MapMarker/MapMarker';
import ListItemUserInteraction from '../../../ListItemUserInteraction/ListItemUserInteraction';

import styles from './CommunityActivitiesListItem.module.scss';

import type { CommunityActivitiesListItem_data } from './__generated__/CommunityActivitiesListItem_data.graphql';

type Props = {
  relay: { environment: Object },
  data: CommunityActivitiesListItem_data,
  className?: string,
  createdAt?: string,
  mini?: boolean,
  toggleSearchPublicModal: Function,
};

/**
 * Passes back the location to route to as well as
 * whether it should open up in a new tab.
 * Currently all notifications will just open in
 * by default
 */
const getCommunityActivityRouteConfig = (
  data,
): {
  target?: '_blank' | '',
  route?: string,
  onClick?: Function,
} => {
  let websiteRoute;
  const target = '';
  const { route } = data || {};

  const { to, user_id, skill_id } = route || {};

  switch (to) {
    case 'skill':
      websiteRoute = `/skills/${skill_id}`;
      break;
    // USER_PUBLISHED
    // POSITION_ADDED
    // LOCATION_CHANGED
    case 'profile':
      websiteRoute = `/${user_id}`;
      break;
    // SEARCH_LOOKING_FOR <--- implemented in CommunityActivityListItemSearchLookingFor
    default:
      websiteRoute = '';
      break;
  }

  return {
    route: websiteRoute,
    target: websiteRoute && websiteRoute !== '#' ? target : '',
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
};

const CommunityActivitiesListItem = ({
  data,
  className,
  createdAt: propCreatedAt,
  mini,
  // redux
  toggleSearchPublicModal,
  ...rest
}: Props) => {
  const isMaxMd = useMedia({ maxWidth: responsive.minDeviceWidthMD });
  const { type, created_at, title_short, title, emoji, location } = data || {};
  const { route = '', target: linkTarget } = getCommunityActivityRouteConfig(
    data,
  );

  /**
   * Returns the extra props based on the type of the activity
   */
  const _getTypeBasedProps = () => {
    switch (type) {
      case COMMUNITY_ACTIVITY_TYPES.SEARCH_LOOKING_FOR:
        return {
          buttonProps: {
            onClick: (e) => {
              const { users: [user] = [], user_search } = data || {};
              e.preventDefault();
              e.stopPropagation();
              toggleSearchPublicModal({
                isOpen: true,
                payload: {
                  title: user_search.tag?.title || user_search.term,
                  user,
                },
              });
            },
            children: 'Refer',
          },
        };
      case COMMUNITY_ACTIVITY_TYPES.LOCATION_CHANGED:
        return {
          children: (
            <div className={styles['map-container']}>
              <PluralMap
                center={location}
                withLocateMeBtn={false}
                disableFullscreen
                renderSiblings={() => (
                  <MapMarker
                    user={data?.users?.[0]}
                    className={styles['position-marker']}
                  />
                )}
                options={{
                  draggable: false,
                  draggableCursor: 'pointer',
                  zoomControl: false,
                  scrollwheel: false,
                }}
                height="200px"
                width="100%"
                className={styles.map}
                withMarkerPopUp={false}
                nonInteractive
                logoPosition="bottom-left"
              />
            </div>
          ),
        };
      default:
        return {};
    }
  };

  return (
    <ListItemUserInteraction
      className={classNames([styles['link-wrapper'], className])}
      {...(route ? { to: route, target: linkTarget } : {})}
      mini={mini ?? isMaxMd}
      users={data?.users}
      bodyText={`${emoji || ''}  ${title_short || title}`.trim()}
      dateTime={propCreatedAt || created_at}
      {..._getTypeBasedProps()}
      {...rest}
    />
  );
};

export { CommunityActivitiesListItem as PureCommunityActivitiesListItem };

export default createFragmentContainer(
  compose(
    connect(null, {
      toggleSearchPublicModal: toggleSearchPublicModalAction,
    }),
  )(CommunityActivitiesListItem),
  {
    data: graphql`
      # @todo @cleanup move into sub-fragments
      fragment CommunityActivitiesListItem_data on CommunityActivity {
        id
        type
        route {
          to
          user_id
          skill_id
        }
        created_at
        title_short
        emoji
        users {
          id
          first_name
          last_name
          profile_color
          avatar {
            id
            smallThumbnail
          }
        }
        location {
          id
          longitude
          latitude
        }
        user_search {
          id
          tag {
            id
            title
          }
          term
        }
      }
    `,
  },
);
