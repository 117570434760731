/* @flow */
import React from 'react';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';

import FancyDialog from '../FancyDialog/FancyDialog';
import { innerRoutesHelpers } from '../../utils';
import styles from './FinishYourSkillModal.module.scss';

type Props = {
  skill: any,
  userFirstName: ?string,
  onToggle: Function,
  isOpen: boolean,
  history: ?any,
};

const FinishYourSkillModal = ({
  skill,
  userFirstName,
  onToggle,
  history,
  isOpen,
}: Props) => (
  <FancyDialog
    isOpen={isOpen}
    toggle={onToggle}
    title="Finish your skill"
    bodyText={`Great progress, ${userFirstName}! Now let’s get some details about your skill "${get(
      skill,
      'title',
      '',
    )}" so you can publish it.`}
    primaryBtnText="Let's do it!"
    primaryBtnOnClick={() => {
      onToggle(false);
      history.push(innerRoutesHelpers.getEditSkillLink(skill));
    }}
    secondaryBtnText="Maybe later"
    secondaryBtnOnClick={onToggle}
    secondaryBtnClassName={styles['dialog-secondary-btn']}
    closeBtnOnClick={onToggle}
  />
);

export { FinishYourSkillModal as FinishYourSkillModalPure };
export default withRouter(FinishYourSkillModal);
