/* @flow */
import React from 'react';
import classNames from 'classnames';

import styles from './SkillTag.module.scss';

type Props = {
  children: React$Node,
  className?: string,
  error: boolean | string,
};

const SkillTag = React.forwardRef(
  ({ className, children, error, ...rest }: Props, ref) => (
    <div
      ref={ref}
      className={classNames([
        styles.tag,
        { [styles[error]]: error },
        className,
      ])}
      {...rest}
    >
      {children}
    </div>
  ),
);

export default SkillTag;
