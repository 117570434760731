/* @flow */
/** @todo @vova @test */
import { useEffect } from 'react';
import HistoryHelpers from '../../services/HistoryHelpers/HistoryHelpers';

/**
 * useUpdateUrlWithUsernameEffect hook
 *
 * Updates the `/profile` part of the pathname with the actual username
 */
const useUpdateUrlWithUsernameEffect = (username, match) => {
  const { path = '' } = match || {};
  /** Update url with username */
  useEffect(() => {
    if (username && ['/:username', '/profile'].includes(path)) {
      HistoryHelpers.replaceUrlLocation({
        pathname: `/${username}`,
      });
    }
  }, [username, path]);
};

export default useUpdateUrlWithUsernameEffect;
