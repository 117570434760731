/* @flow */
import React from 'react';
import classNames from 'classnames';
import { TouchableOpacity } from '@pluralcom/blueprint';

import FontAwesomeIcon from '../../../FontAwesomeIcon/FontAwesomeIcon';

import styles from './SelectedTileItem.module.scss';

type Props = {
  onClick?: Function,
  title?: string,
  renderLeftContent?: Function,
  renderTitle?: Function,
  withRightIcon?: boolean,
  className?: string,
};

const SelectedTileItem = ({
  onClick,
  title,
  renderLeftContent,
  renderTitle,
  withRightIcon = true,
  className,
}: Props) => (
  <TouchableOpacity
    onClick={onClick}
    className={classNames(styles.container, className)}
  >
    <div className={styles['left-container']}>
      <div className={styles['left-subcontainer']}>
        {renderLeftContent && renderLeftContent()}
      </div>
      {renderTitle ? renderTitle() : <p className={styles.title}>{title}</p>}
    </div>
    {withRightIcon && (
      <FontAwesomeIcon icon={['fas', 'caret-down']} className={styles.icon} />
    )}
  </TouchableOpacity>
);

export default SelectedTileItem;
