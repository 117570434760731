/* @flow */
import React from 'react';
import classNames from 'classnames';

import TimeAgo from '../../../TimeAgo/TimeAgo';

import styles from './ListItemUserInteractionTimeAgo.module.scss';

interface ListItemUserInteractionTimeAgoProps
  extends React.ComponentProps<typeof TimeAgo> {
  /** custom className */
  className?: string;
}

const ListItemUserInteractionTimeAgo = ({
  className,
  ...rest
}: ListItemUserInteractionTimeAgoProps) => (
  <TimeAgo className={classNames([styles.time])} {...rest} />
);

export default ListItemUserInteractionTimeAgo;
