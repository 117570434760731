/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation CorrectKnowsPhoneMutation($input: CorrectKnowsPhoneInput!) {
    correctKnowsPhone(input: $input) {
      error {
        message
      }
      oldKnowsPhoneId
      userEdge {
        node {
          ...UsersSectionItem_user
        }
      }
    }
  }
`;

const correctKnowsPhoneMutation = (
  {
    current_value,
    new_value,
  }: {
    current_value: string,
    new_value: string,
  },
  {
    parentID,
  }: {
    parentID?: string,
  } = {},
): Promise<any> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        current_value,
        new_value,
      },
    };
    commitMutation({
      mutation,
      variables,

      configs: [
        // add the corrected phone number's ghost user/user to unified network
        {
          type: 'RANGE_ADD',
          parentID,
          connectionInfo: [
            {
              key: 'PeopleSelector_UsersSection_unifiedNetwork',
              rangeBehavior: 'prepend',
            },
          ],
          edgeName: 'userEdge',
        },
        // delete the old invalid phone number's ghost user from unified network
        {
          type: 'RANGE_DELETE',
          parentID,
          connectionKeys: [
            {
              key: 'PeopleSelector_UsersSection_unifiedNetwork',
            },
          ],
          deletedIDFieldName: 'oldUserId',
          pathToConnection: ['viewer', 'search', 'unifiedNetwork'],
        },
      ],

      onCompleted: resolve,
      onError: reject,
    });
  });

export default correctKnowsPhoneMutation;
