/* @flow */

/** Gets branch auth config */
const config: {
  app_id: string,
  key: string,
} = {
  app_id: process.env.REACT_APP_BRANCH_APP_ID || '335917085009924711',
  key:
    process.env.REACT_APP_BRANCH_KEY ||
    'key_test_ioqlIONtyTIHYp67mx8IamodDtdLzNr2',
};

export default config;
