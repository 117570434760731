import React from 'react';
import classNames from 'classnames';
import { Text } from '@pluralcom/blueprint';

import styles from './ListItemUserInteractionUserName.module.scss';

interface ListItemUserInteractionUserNameProps {
  /** custom className */
  className?: string;
  /** users list */
  users: Array<{
    /** user id */ 
    id: string,
    /** user first name */
    first_name: string,
  }>;
  /** whether or not to render in mini form */
  mini?: boolean;
}

const ListItemUserInteractionUserName = ({
  users = [],
  className,
  mini,
}: ListItemUserInteractionUserNameProps) => {
  const user = users?.[0];

  return user ? (
    <Text
      fontSizeType="t6"
      className={classNames([
        styles.container,
        { [styles.mini]: mini },
        className,
      ])}
    >
      {user.first_name}
    </Text>
  ) : null;
};

export default ListItemUserInteractionUserName;
