/* eslint-disable react/prop-types */
/* @flow */
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import List from 'react-virtualized/dist/commonjs/List';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import WindowScroller from 'react-virtualized/dist/commonjs/WindowScroller';

import isEqual from 'lodash/isEqual';

import Spinner from '../Spinner/Spinner';

import styles from './VirtualizedListColumned.module.scss';

type Props = {
  className?: string,
  height?: number,
  windowScrollerEnabled?: boolean,
  overscanRowCount?: number,

  isLoading?: Function,
  hasMore?: Function,
  loadMore?: Function,
  numOfItemsLeftForRefetch?: number,

  itemsCount: number,
  rowHeight: number,
  itemSize?: number, // undefined
  itemsPerRow?: number, // 1
  rowClassName?: string,

  /** If true, auto calculates height */
  autoHeight?: boolean,
  windowScrollerProps?: Object,
  renderColumn: Function,
  containerClassName?: string,
};

class VirtualizedListColumned extends PureComponent<*, Props, *> {
  /** Maintains the previous scrolltop position to caclulate the scrollDriection */
  _listScrollTop = 0;

  constructor(props) {
    super(props);
    this._windowScrollerRef = React.createRef();
    this._listRef = React.createRef();
  }

  componentDidUpdate = (prevProps) => {
    const { windowScrollerEnabled } = this.props;
    if (windowScrollerEnabled && !isEqual(prevProps, this.props)) {
      this._forceUpdateWindowScroller();
    }
  };

  _forceUpdateWindowScroller = () => {
    if (this._windowScrollerRef.current) {
      this._windowScrollerRef.current.forceUpdate();
    }
  };

  _isRowLoaded = ({ index }: { index: number }) => {
    const { itemsCount, hasMore } = this.props;
    const itemsPerRow = this._itemsPerRow || 1;
    return !hasMore() || index / itemsPerRow < itemsCount;
  };

  _renderLoadingSpinner = () => <Spinner />;

  _renderColumn = ({ index, key, itemsPerRow }) => {
    const { renderColumn } = this.props;
    return renderColumn({ index, key, itemsPerRow });
  };

  _renderListItem = ({ itemsCount, itemsPerRow, rowClassName }) => ({
    index,
    key,
    style,
  }) => {
    let content;

    if (!this._isRowLoaded({ index })) {
      content = this._renderLoadingSpinner();
    } else {
      const items = [];
      const fromIndex = index * itemsPerRow;
      const toIndex = Math.min(fromIndex + itemsPerRow, itemsCount);

      for (let i = fromIndex; i < toIndex; i += 1) {
        items.push(
          this._renderColumn({ index: i, key: `${key}_${i}}`, itemsPerRow }),
        );
      }
      content = items;
    }

    return (
      <div
        key={key}
        style={style}
        className={classNames([styles['list-item-wrapper'], rowClassName])}
      >
        {content}
      </div>
    );
  };

  _handleListScroll = (nextFunc) => (options) => {
    const {
      numOfItemsLeftForRefetch,
      isLoading,
      hasMore,
      loadMore,
      rowHeight,
    } = this.props;

    this._lastScrollOptoins = options;

    const { scrollTop, scrollHeight, clientHeight } = options;
    if (loadMore && hasMore) {
      const isScrollingDown = scrollTop - this._listScrollTop > 0;

      const numOfPixelsLeft = scrollHeight - scrollTop - clientHeight;
      const numOfPixelsLeftForRefetch = numOfItemsLeftForRefetch * rowHeight;

      if (
        isScrollingDown &&
        numOfPixelsLeft <= numOfPixelsLeftForRefetch &&
        !isLoading() &&
        hasMore()
      ) {
        loadMore().then(() => {
          this._forceUpdateWindowScroller();
        });
      }
    }
    this._listScrollTop = scrollTop;
    if (nextFunc) {
      nextFunc(options);
    }
  };

  _renderList = ({
    height,
    registerChild,
    isScrolling,
    onChildScroll,
    scrollTop,
  }) => ({ width }) => {
    const {
      windowScrollerEnabled,
      overscanRowCount,
      className,
      itemsCount,
      rowHeight,
      itemSize,
      autoHeight,
      rowClassName,
      // filter,
      height: filteredHeight,
      ...rest
    } = this.props;

    // if itemSize === width, then itemsPerRow = 1
    const synItemSize = itemSize || width;

    /** Construct Grid like row to display make the list responsive */
    const itemsPerRow = Math.floor(width / synItemSize);
    const rowCount = Math.ceil(itemsCount / (itemsPerRow || synItemSize));

    this._itemsPerRow = itemsPerRow;

    let synHeight = height;
    if (autoHeight) {
      synHeight = rowCount * rowHeight;
    }

    const child = (
      <List
        ref={this._listRef}
        autoHeight={windowScrollerEnabled}
        className={classNames([styles.list, className])}
        height={windowScrollerEnabled ? height : synHeight}
        isScrolling={isScrolling}
        onScroll={this._handleListScroll(onChildScroll)}
        scrollTop={scrollTop}
        rowCount={rowCount}
        rowHeight={rowHeight}
        rowRenderer={this._renderListItem({
          itemsCount,
          itemsPerRow,
          rowClassName,
        })}
        overscanRowCount={overscanRowCount}
        width={width}
        {...rest}
      />
    );

    if (windowScrollerEnabled) {
      return <div ref={registerChild}>{child}</div>;
    }

    return child;
  };

  _renderAutoSizer = ({
    height,
    isScrolling,
    registerChild,
    onChildScroll,
    scrollTop,
  }) => {
    const { windowScrollerEnabled, containerClassName } = this.props;
    return (
      <div
        className={classNames([styles['window-scroller'], containerClassName])}
      >
        <AutoSizer
          disableHeight
          height={windowScrollerEnabled ? undefined : height}
        >
          {this._renderList({
            height,
            registerChild,
            isScrolling,
            onChildScroll,
            scrollTop,
          })}
        </AutoSizer>
      </div>
    );
  };

  render() {
    const { windowScrollerEnabled, windowScrollerProps, height } = this.props;

    let child;
    if (windowScrollerEnabled) {
      child = (
        <WindowScroller ref={this._windowScrollerRef} {...windowScrollerProps}>
          {this._renderAutoSizer}
        </WindowScroller>
      );
    } else {
      child = this._renderAutoSizer({ height });
    }

    return child;
  }
}

VirtualizedListColumned.defaultProps = {
  className: undefined,
  height: 600,
  windowScrollerEnabled: false,
  overscanRowCount: 8,
  autoHeight: undefined,
  windowScrollerProps: undefined,

  isLoading: undefined,
  hasMore: undefined,
  loadMore: undefined,
  numOfItemsLeftForRefetch: 4,
};

export default VirtualizedListColumned;
