/**
 * @generated SignedSource<<6a66b68b4262c3f4fa5ec5f1c47a08ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SignInFacebookInput = {
  accessToken: string;
  clientMutationId?: string | null;
  with_auth?: boolean | null;
};
export type SigninFacebookMutation$variables = {
  input: SignInFacebookInput;
};
export type SigninFacebookMutation$data = {
  readonly signinFacebook: {
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly intercom_hash: string | null;
    readonly profile: {
      readonly avatar: {
        readonly id: string;
        readonly medium: string | null;
        readonly smallThumbnail: string | null;
        readonly thumbnail: string | null;
      } | null;
      readonly id: string;
      readonly profile_color: string | null;
      readonly remote_user_facebook: {
        readonly id: string;
        readonly name: string | null;
      } | null;
      readonly username: string | null;
      readonly verified_facebook: boolean | null;
    } | null;
    readonly roles: ReadonlyArray<string | null> | null;
  } | null;
};
export type SigninFacebookMutation = {
  response: SigninFacebookMutation$data;
  variables: SigninFacebookMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "profile",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "verified_facebook",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profile_color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "avatar",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "smallThumbnail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "medium",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RemoteUser",
      "kind": "LinkedField",
      "name": "remote_user_facebook",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "roles",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "intercom_hash",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SigninFacebookMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SignInFacebookPayload",
        "kind": "LinkedField",
        "name": "signinFacebook",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SigninFacebookMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SignInFacebookPayload",
        "kind": "LinkedField",
        "name": "signinFacebook",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c78d49f070157ce0ba85cc878b40caaf",
    "id": null,
    "metadata": {},
    "name": "SigninFacebookMutation",
    "operationKind": "mutation",
    "text": "mutation SigninFacebookMutation(\n  $input: SignInFacebookInput!\n) {\n  signinFacebook(input: $input) {\n    profile {\n      id\n      username\n      verified_facebook\n      profile_color\n      avatar {\n        id\n        smallThumbnail\n        thumbnail\n        medium\n      }\n      remote_user_facebook {\n        id\n        name\n      }\n    }\n    roles\n    intercom_hash\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dec0885a0b57df4b833baa7b85db19cd";

export default node;
