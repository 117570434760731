/* @flow */
import React from 'react';
import List from 'react-virtualized/dist/commonjs/List';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';

type Props = {
  height?: number,
  width?: number,
  rowRenderer: Function,
  rowHeight: Function,
  headerRenderer?: Function,
  headerHeight?: number,
  footerRenderer?: Function,
  footerHeight: number,
  data: Array<Object>,
  rowCount: number,
  index: number,
};

/**
 * Simple Flat list component based on react-virtualized List
 *  - Header render support
 *  - Footer redder support
 *  - see more doc here https://github.com/bvaughn/react-virtualized/blob/master/docs/List.md
 *
 *  !!! important If you pass headerRenderer param, FlatList will render + 1 element,
 *    so use data form params inside of rowHeight and rowRenderer methods
 *
 *    rowHeight=(({ data, index }) => data[index]...)
 *
 *  @example
          <FlatList
           headerRenderer={({ data, index, style, ...rest }) => <p style={style}>data[index]<p>}
           headerHeight={50}
           rowCount={activities.length}
           rowRenderer={({ data, index, style, ...rest }) => <p style={style}>data[index]<p>}
           rowHeight={({ data, index, style, ...rest }) => <p style={style}>data[index]<p>}
           data={activities}
          />
 *
 * * */

const PureFlatList = ({
  width,
  height,
  rowRenderer,
  rowHeight,
  headerRenderer,
  headerHeight,
  footerRenderer,
  footerHeight,
  rowCount,
  data,
  ...rest
}: Props) => {
  const withHeader = Boolean(headerRenderer);
  const withFooter = Boolean(footerRenderer);
  const newData = withHeader || withFooter ? [...data] : data;
  if (withHeader) {
    newData.unshift({ id: `flat_list_header_row${Math.random()}` });
  }
  if (withFooter) {
    newData.push({ id: `flat_list_footer_row${Math.random()}` });
  }
  const rowRendererSpy = (props: { index: number }) => {
    const { index } = props;
    if (index === 0 && withHeader) {
      return headerRenderer({ data: newData, ...props });
    }
    if (index === newData.length - 1 && withFooter) {
      return footerRenderer({ data: newData, ...props });
    }
    return rowRenderer({ data: newData, ...props });
  };

  const rowHeightSpy = (props: { index: number }) => {
    const { index } = props;
    if (index === 0 && withHeader) {
      return headerHeight;
    }
    if (index === newData.length - 1 && withFooter) {
      return footerHeight;
    }
    return typeof rowHeight === 'function'
      ? rowHeight({ data: newData, ...props })
      : rowHeight;
  };
  return (
    <List
      height={height}
      width={width}
      rowRenderer={rowRendererSpy}
      rowHeight={rowHeightSpy}
      rowCount={newData.length}
      {...rest}
    />
  );
};

const FlatList = ({
  height,
  width,
  ...rest
}: {
  height: number,
  width: number,
}) =>
  !(width && height) ? (
    <AutoSizer disableHeight={Boolean(height)} disableWidth={Boolean(width)}>
      {(autosize) => (
        <PureFlatList
          {...rest}
          height={height || autosize.height}
          width={width || autosize.width}
        />
      )}
    </AutoSizer>
  ) : (
    <PureFlatList height={height} width={width} {...rest} />
  );
export { PureFlatList };
export default FlatList;
