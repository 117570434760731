/**
 * @generated SignedSource<<47a482e0b8e0638763ac91455ccefb6d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FindFriendsSection_viewer$data = {
  readonly profile: {
    readonly verified_facebook: boolean | null;
  } | null;
  readonly userGrantedPermissions: {
    readonly phonebook: {
      readonly id: string;
      readonly value: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "FindFriendsSection_viewer";
};
export type FindFriendsSection_viewer$key = {
  readonly " $data"?: FindFriendsSection_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"FindFriendsSection_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FindFriendsSection_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "verified_facebook",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserGrantedPermission",
      "kind": "LinkedField",
      "name": "userGrantedPermissions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Permission",
          "kind": "LinkedField",
          "name": "phonebook",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ViewerType",
  "abstractKey": null
};

(node as any).hash = "c15355340f928e0ed0564a8309a3d6ed";

export default node;
