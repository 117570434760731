/* @flow */
import React, { useState } from 'react';
import classNames from 'classnames';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import ButtonShareViaSocialNetworks from '../../../ButtonShareViaSocialNetworks/ButtonShareViaSocialNetworks';
import ButtonShareViaPhoneBook from '../../../ButtonShareViaPhoneBook/ButtonShareViaPhoneBook';

import Alertbox from '../../../AlertBox/AlertBox';
import withFacebook from '../../../../hocs/withFacebook/withFacebook';

import styles from './FindFriendsSection.module.scss';
import ModalComingSoon from '../../../ModalComingSoon';

type Props = {
  handleLogin: Function,
  fbAuthError?: string,
  viewer: {
    profile?: {
      verified_facebook: boolean,
    },
    userGrantedPermissions?: {
      phonebook?: {
        id: string,
        value: boolean,
      },
    },
  },
};

const FindFriendsSection = ({ handleLogin, fbAuthError, viewer }: Props) => {
  const { profile, userGrantedPermissions } = viewer || {};
  const { verified_facebook = false } = profile || {};
  const { phonebook = {} } = userGrantedPermissions || {};
  const [isComingSoonModalOpen, setIsComingSoonModalOpen] = useState(false);

  return (
    <div className={styles.container}>
      <ButtonShareViaSocialNetworks
        type="facebook"
        onClick={handleLogin}
        text={verified_facebook ? 'Facebook connected' : 'Facebook'}
        disabled={verified_facebook}
        className={styles.button}
      />

      <ButtonShareViaPhoneBook
        type="phonebook"
        disabled={phonebook?.value}
        text={phonebook?.value ? 'Phonebook connected' : 'Phonebook'}
        className={styles.button}
      />

      <ButtonShareViaSocialNetworks
        type="linkedin"
        onClick={() => setIsComingSoonModalOpen(true)}
        text="Linkedin"
        className={styles.button}
      />

      <ButtonShareViaSocialNetworks
        type="google"
        onClick={() => setIsComingSoonModalOpen(true)}
        text="Google"
        className={styles.button}
      />

      <Alertbox fbAuthError={fbAuthError} />

      <ModalComingSoon
        isOpen={isComingSoonModalOpen}
        toggle={() => setIsComingSoonModalOpen(false)}
      />
    </div>
  );
};
export { FindFriendsSection as PureFindFriendsSection };
// export default FindFriendsSection;
export default createFragmentContainer(
  withFacebook({ triggerRelayRefresh: true })(FindFriendsSection),
  {
    viewer: graphql`
      fragment FindFriendsSection_viewer on ViewerType {
        profile {
          verified_facebook
        }
        userGrantedPermissions {
          phonebook {
            id
            value
          }
        }
      }
    `,
  },
);
