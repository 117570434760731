/* @flow */
import React, { Component } from 'react';
import classNames from 'classnames';
import {
  asYouType,
  stringManipulation,
  libphonenumberHelpers,
} from '@pluralcom/plural-js-utils';
import copyToClipboard from 'copy-to-clipboard';
import { TouchableOpacity } from '@pluralcom/blueprint';

import Input from '../Input/Input';
import TileItem from '../TileItem/TileItem';
import FontAwesomeIcon from '../FontAwesomeIcon/FontAwesomeIcon';

import { uiDomHelpers, dateStringFormatter } from '../../utils';

import styles from './TileInputItem.module.scss';

type Props = {
  value: string,
  error?: ?string,
  className?: ?string,
  errorDataTestId?: ?string,
  containerClassName?: ?string,
  seperatorClassName?: ?string,
  wrapperClassName?: ?string,
  icon?: ?string | ?Array<string>,
  iconClassName?: ?string,
  iconActiveClassName?: ?string,
  children: React$Node,
  inputRef?: Function,
  onChange?: Function,
  onFocus?: Function,
  onBlur?: Function,
  onPaste?: Function,
  onKeyUp?: Function,
  tileItemProps?: ?Object,
  withAutocompleteMenu?: boolean,
  placeholder?: string,
  focusPlaceholder?: string,
  enhancedInputType:
    | 'numeric'
    | 'manualdate_mm/yyyy'
    | 'manualdate_mm/dd/yyyy'
    | 'email_phone'
    | undefined,
  autoFocus?: boolean,
  showClearBtn?: boolean,
  clearBtnOnClick?: Function,
  prefix?: ?string,
  prefixProps?: ?Object,
  renderIcon: Function,
};

class TileInputItem extends Component<*, Props, *> {
  _inputRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = { isOpenMenu: false, focused: false };
  }

  componentDidMount = () => {
    const { autoFocus } = this.props;
    setTimeout(() => {
      if (autoFocus && this._inputRef) {
        this._inputRef.focus();
      }
      /** should wait till popup animation end */
    }, 500);
  };

  _setInputRef = (el) => {
    this._inputRef = el;
    if (this.props.inputRef) {
      this.props.inputRef(el);
    }
  };

  _onChange = (e) => {
    const { withAutocompleteMenu, onChange, enhancedInputType } = this.props;
    const { value } = e.target;

    if (withAutocompleteMenu) {
      if (value === '') {
        this.setState({ isOpenMenu: false });
      } else {
        this.setState({ isOpenMenu: true });
      }
    }

    if (enhancedInputType && onChange) {
      const enhancedEvent = e;
      const caretPos = e.target.selectionStart;
      let synthesizedValue = value;

      switch (enhancedInputType) {
        case 'numeric':
          enhancedEvent.target.value = stringManipulation.replaceNonNumeric(
            value,
          );
          break;
        case 'manualdate_mm/yyyy':
          enhancedEvent.target.value = dateStringFormatter.monthYear(value);
          break;
        case 'manualdate_mm/dd/yyyy':
          enhancedEvent.target.value = dateStringFormatter.monthDayYear(value);
          break;
        case 'email_phone':
          synthesizedValue = asYouType('email_phone', value);
          enhancedEvent.target.value = synthesizedValue;
          if (this._inputRef) {
            uiDomHelpers.setCaretPosition(
              this._inputRef,
              synthesizedValue.length === value.length
                ? caretPos
                : synthesizedValue.length,
            );
          }
          break;
        default:
      }
      onChange(enhancedEvent);
      return;
    }

    if (onChange) {
      onChange(e);
    }
  };

  _onPaste = (e) => {
    const { enhancedInputType, onPaste } = this.props;
    if (enhancedInputType === 'email_phone') {
      /** Synthesized apple text -- @important: apple contacts appends weird characters that need to be cleaned */
      const originalText = stringManipulation.synthesizeAppleText(
        e.clipboardData.getData('Text'),
      );
      /** Synthesize and parse the value if a possible phone number */
      if (libphonenumberHelpers.isPossiblePhoneStr(originalText)) {
        // Update clipboard text with synthesized value to be formatted by onChange
        copyToClipboard(originalText.replace(/[^0-9+]/g, ''));
        // Update clipboard text with original value to restore user's initial clipboard state
        setTimeout(() => {
          copyToClipboard(originalText);
        }, 10);
      }
    }
    if (typeof onPaste === 'function') {
      onPaste(e);
    }
  };

  _onFocus = (e) => {
    let nextState = { focused: true };
    if (
      this.props.withAutocompleteMenu &&
      this.props.value &&
      this.props.value !== ''
    ) {
      nextState = { ...nextState, isOpenMenu: true };
    }
    this.setState(nextState);
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  };

  _onBlur = (e) => {
    this.setState({ focused: false });
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  };

  _onKeyUp = (e) => {
    if (this.props.onKeyUp) {
      this.props.onKeyUp(e);
    } else {
      const { key } = e;
      if (key === 'Enter') {
        if (this.props.withAutocompleteMenu) {
          this.setState({ isOpenMenu: false });
        }
      }
    }
  };

  _handleCloseMenu = () => {
    this.setState({ isOpenMenu: false });
  };

  _getPlaceholder = ({ placeholder, focusPlaceholder }) => {
    if (focusPlaceholder && uiDomHelpers.isActiveElement(this._inputRef)) {
      return focusPlaceholder;
    }
    return placeholder;
  };

  _clear = () => {
    this._onChange({
      target: {
        value: '',
        selectionStart: 0,
      },
    });
  };

  render() {
    const {
      error,
      className,
      containerClassName,
      seperatorClassName,
      wrapperClassName,
      icon,
      iconClassName,
      iconActiveClassName,
      children,
      value,
      showClearBtn,
      clearBtnOnClick,
      prefix,
      prefixProps,
      // Destructuring filter
      autoFocus,
      inputRef,
      onFocus,
      onBlur,
      onKeyUp,
      onChange,
      tileItemProps,
      withAutocompleteMenu,
      placeholder,
      focusPlaceholder,
      enhancedInputType,
      renderIcon,
      errorDataTestId,
      ...rest
    } = this.props;
    const { isOpenMenu, focused } = this.state;
    let enhancedInputTypeProps = {};
    if (enhancedInputType === 'numeric') {
      enhancedInputTypeProps = { ...enhancedInputTypeProps, pattern: '[0-9]*' };
    } else if (enhancedInputType === 'email_phone') {
      enhancedInputTypeProps = { ...enhancedInputTypeProps, type: 'text' };
    }
    return (
      <TileItem
        noWrapper
        error={error}
        errorDataTestId={errorDataTestId}
        containerClassName={containerClassName}
        showMenu={isOpenMenu}
        onCloseMenu={this._handleCloseMenu}
        {...tileItemProps}
      >
        <div
          className={classNames([
            styles['input-wrapper'],
            { [styles['with-icon']]: Boolean(icon || renderIcon) },
            wrapperClassName,
          ])}
        >
          {prefix ? (
            <span
              {...prefixProps}
              className={classNames([styles.prefix, prefixProps?.className])}
            >
              {prefix}
            </span>
          ) : null}
          <Input
            className={classNames([
              styles.item,
              { [styles['with-prefix']]: prefix },
              className,
            ])}
            value={value}
            onChange={this._onChange}
            onFocus={this._onFocus}
            onBlur={this._onBlur}
            onKeyUp={this._onKeyUp}
            ref={this._setInputRef}
            placeholder={
              focused ? focusPlaceholder || placeholder : placeholder
            }
            {...enhancedInputTypeProps}
            {...rest}
            onPaste={this._onPaste}
          />
          {icon ? (
            <div className={styles['icon-container']}>
              <FontAwesomeIcon
                icon={icon}
                className={classNames([
                  styles.icon,
                  { [styles['icon-active']]: value && value.length > 0 },
                  iconClassName,
                  { [iconActiveClassName]: value && value.length > 0 },
                ])}
              />
            </div>
          ) : null}
          {renderIcon && (
            <div className={styles['icon-container']}>
              {renderIcon({ isActive: value && value.length > 0 })}
            </div>
          )}
          {children}
          {showClearBtn ? (
            <TouchableOpacity
              className={styles['clear-btn']}
              onClick={clearBtnOnClick || this._clear}
            >
              <FontAwesomeIcon
                icon={['fas', 'times']}
                className={classNames([
                  styles['clear-icon'],
                  iconClassName,
                  { [iconActiveClassName]: value && value.length > 0 },
                ])}
              />
            </TouchableOpacity>
          ) : null}
        </div>
      </TileItem>
    );
  }
}

export default TileInputItem;
