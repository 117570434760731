/* @flow */

/**
 * Please preserve the alphabetic order
 */

/** Brands */
import { definition as faApple } from '@fortawesome/free-brands-svg-icons/faApple';
import { definition as fabBehance } from '@fortawesome/free-brands-svg-icons/faBehance';
import { definition as fabDribbble } from '@fortawesome/free-brands-svg-icons/faDribbble';
import { definition as fabDev } from '@fortawesome/free-brands-svg-icons/faDev';
import { definition as fabFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { definition as fabFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { definition as fabFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { definition as fabFlickr } from '@fortawesome/free-brands-svg-icons/faFlickr';
import { definition as fabGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { definition as fabGooglePlusG } from '@fortawesome/free-brands-svg-icons/faGooglePlusG';
import { definition as fabInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { definition as fabLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { definition as fabMediumM } from '@fortawesome/free-brands-svg-icons/faMediumM';
import { definition as fabPinterestP } from '@fortawesome/free-brands-svg-icons/faPinterestP';
import { definition as fabQuora } from '@fortawesome/free-brands-svg-icons/faQuora';
import { definition as fabStackOverflow } from '@fortawesome/free-brands-svg-icons/faStackOverflow';
import { definition as fabSnapchatGhost } from '@fortawesome/free-brands-svg-icons/faSnapchatGhost';
import { definition as fabTumblr } from '@fortawesome/free-brands-svg-icons/faTumblr';
import { definition as fabTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { definition as fabVimeo } from '@fortawesome/free-brands-svg-icons/faVimeo';
import { definition as fabYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { definition as fabFacebookMessenger } from '@fortawesome/free-brands-svg-icons/faFacebookMessenger';
import { definition as fabGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';
import { definition as fabTiktok } from '@fortawesome/free-brands-svg-icons/faTiktok';
import { definition as fabPatreon } from '@fortawesome/free-brands-svg-icons/faPatreon';
import { definition as fabLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';

/** Regular */
import { definition as farCircle } from '@fortawesome/free-regular-svg-icons/faCircle';
import { definition as farDotCircle } from '@fortawesome/free-regular-svg-icons/faDotCircle';
import { definition as farEyeSlash } from '@fortawesome/free-regular-svg-icons/faEyeSlash';
import { definition as farTrashAlt } from '@fortawesome/free-regular-svg-icons/faTrashAlt';
import { definition as farImage } from '@fortawesome/free-regular-svg-icons/faImage';
import { definition as farClock } from '@fortawesome/free-regular-svg-icons/faClock';
import { definition as farSquare } from '@fortawesome/free-regular-svg-icons/faSquare';
import { definition as farLightbulb } from '@fortawesome/free-regular-svg-icons/faLightbulb';
import { definition as farQuestionCircle } from '@fortawesome/free-regular-svg-icons/faQuestionCircle';
import { definition as farEdit } from '@fortawesome/free-regular-svg-icons/faEdit';
import { definition as farSmile } from '@fortawesome/free-regular-svg-icons/faSmile';
/** Solid */
import { definition as fasAddressBook } from '@fortawesome/free-solid-svg-icons/faAddressBook';
import { definition as fasAddressCard } from '@fortawesome/free-solid-svg-icons/faAddressCard';
import { definition as fasAdjust } from '@fortawesome/free-solid-svg-icons/faAdjust';
import { definition as fasAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { definition as fasAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { definition as fasArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { definition as fasAt } from '@fortawesome/free-solid-svg-icons/faAt';
import { definition as fasBalanceScale } from '@fortawesome/free-solid-svg-icons/faBalanceScale';
import { definition as fasBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { definition as fasBirthdayCake } from '@fortawesome/free-solid-svg-icons/faBirthdayCake';
import { definition as fasBuilding } from '@fortawesome/free-solid-svg-icons/faBuilding';
import { definition as fasBolt } from '@fortawesome/free-solid-svg-icons/faBolt';
import { definition as fasBox } from '@fortawesome/free-solid-svg-icons/faBox';
import { definition as fasCamera } from '@fortawesome/free-solid-svg-icons/faCamera';
import { definition as fasCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { definition as fasChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { definition as fasChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { definition as fasChild } from '@fortawesome/free-solid-svg-icons/faChild';
import { definition as fasCreditCard } from '@fortawesome/free-solid-svg-icons/faCreditCard';
import { definition as fasCoffee } from '@fortawesome/free-solid-svg-icons/faCoffee';
import { definition as fasCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { definition as fasCommentDots } from '@fortawesome/free-solid-svg-icons/faCommentDots';
import { definition as fasCommentsDollar } from '@fortawesome/free-solid-svg-icons/faCommentsDollar';
import { definition as fasCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { definition as fasDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign';
import { definition as fasDove } from '@fortawesome/free-solid-svg-icons/faDove';
import { definition as fasEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { definition as fasEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { definition as fasFemale } from '@fortawesome/free-solid-svg-icons/faFemale';
import { definition as fasFileSignature } from '@fortawesome/free-solid-svg-icons/faFileSignature';
import { definition as fasFile } from '@fortawesome/free-solid-svg-icons/faFile';
import { definition as fasGift } from '@fortawesome/free-solid-svg-icons/faGift';
import { definition as fasGlobeAmericas } from '@fortawesome/free-solid-svg-icons/faGlobeAmericas';
import { definition as fasGraduationCap } from '@fortawesome/free-solid-svg-icons/faGraduationCap';
import { definition as fasImage } from '@fortawesome/free-solid-svg-icons/faImage';
import { definition as fasLanguage } from '@fortawesome/free-solid-svg-icons/faLanguage';
import { definition as fasLightbulb } from '@fortawesome/free-solid-svg-icons/faLightbulb';
import { definition as fasLocationArrow } from '@fortawesome/free-solid-svg-icons/faLocationArrow';
import { definition as fasMale } from '@fortawesome/free-solid-svg-icons/faMale';
import { definition as fasMagnifyingGlass } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlass';
import { definition as fasMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import { definition as fasMobileScreenButton } from '@fortawesome/free-solid-svg-icons/faMobileScreenButton';
import { definition as faHandHoldingHeart } from '@fortawesome/free-solid-svg-icons/faHandHoldingHeart';
import { definition as fasHeart } from '@fortawesome/free-solid-svg-icons/faHeart';
import { definition as fasLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { definition as fasPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { definition as fasPhoneSquare } from '@fortawesome/free-solid-svg-icons/faPhoneSquare';
import { definition as fasPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { definition as fasPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { definition as fasQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion';
import { definition as fasQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { definition as fasQrcode } from '@fortawesome/free-solid-svg-icons/faQrcode';
import { definition as fasRedo } from '@fortawesome/free-solid-svg-icons/faRedo';
import { definition as fasRocket } from '@fortawesome/free-solid-svg-icons/faRocket';
import { definition as fasSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { definition as fasStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { definition as fasStopwatch } from '@fortawesome/free-solid-svg-icons/faStopwatch';
import { definition as fasSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { definition as fasPaperclip } from '@fortawesome/free-solid-svg-icons/faPaperclip';
import { definition as fasTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { definition as faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { definition as fasUniversity } from '@fortawesome/free-solid-svg-icons/faUniversity';
import { definition as fasLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { definition as fasUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { definition as fasUserAstronaut } from '@fortawesome/free-solid-svg-icons/faUserAstronaut';
import { definition as fasUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { definition as fasUserEdit } from '@fortawesome/free-solid-svg-icons/faUserEdit';
import { definition as fasUserShield } from '@fortawesome/free-solid-svg-icons/faUserShield';
import { definition as fasSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { definition as fasEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH';
import { definition as fasTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { definition as fasFileAlt } from '@fortawesome/free-solid-svg-icons/faFileAlt';
import { definition as fasFlag } from '@fortawesome/free-solid-svg-icons/faFlag';
import { definition as fasEnvelopeOpen } from '@fortawesome/free-solid-svg-icons/faEnvelopeOpen';
import { definition as fasShieldAlt } from '@fortawesome/free-solid-svg-icons/faShieldAlt';
import { definition as fasShieldHeart } from '@fortawesome/free-solid-svg-icons/faShieldHeart';
import { definition as fasCaretRight } from '@fortawesome/free-solid-svg-icons/faCaretRight';
import { definition as fasCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { definition as fasEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { definition as fasCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons/faCloudDownloadAlt';
import { definition as fasBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { definition as fasCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare';
import { definition as fasCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { definition as fasCloudUploadAlt } from '@fortawesome/free-solid-svg-icons/faCloudUploadAlt';
import { definition as fasClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { definition as fasInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { definition as fasShare } from '@fortawesome/free-solid-svg-icons/faShare';
import { definition as fasHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { definition as fasGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';
import { definition as fasVideo } from '@fortawesome/free-solid-svg-icons/faVideo';
import { definition as fasXmark } from '@fortawesome/free-solid-svg-icons/faXmark';

/** Fontawesome libraryIcons */
export default [
  /** Brands */
  faApple,
  fabBehance,
  fabDribbble,
  fabDev,
  fabFacebook,
  fabFacebookF,
  fabFacebookSquare,
  fabFlickr,
  fabGithub,
  fabGooglePlusG,
  fabInstagram,
  fabLinkedin,
  fabMediumM,
  fabPinterestP,
  fabQuora,
  fabStackOverflow,
  fabSnapchatGhost,
  fabTumblr,
  fabTwitter,
  fabVimeo,
  fabYoutube,
  fabFacebookMessenger,
  fabGoogle,
  fabTiktok,
  fabPatreon,
  fabLinkedinIn,
  /** Regular */
  farCircle,
  farDotCircle,
  farEyeSlash,
  farTrashAlt,
  farImage,
  farClock,
  farSquare,
  farLightbulb,
  farQuestionCircle,
  farEdit,
  farSmile,
  /** Solid */
  fasAddressBook,
  fasAddressCard,
  fasAngleRight,
  fasAngleLeft,
  fasArrowLeft,
  fasAt,
  fasBalanceScale,
  fasBars,
  fasBirthdayCake,
  fasBuilding,
  fasBolt,
  fasBox,
  fasCamera,
  fasCheckCircle,
  fasChevronLeft,
  fasChevronRight,
  fasChild,
  fasCoffee,
  fasCog,
  fasCommentDots,
  fasCommentsDollar,
  fasCopy,
  fasDollarSign,
  fasDove,
  fasEnvelope,
  fasEye,
  fasFemale,
  fasFileSignature,
  fasGift,
  fasGraduationCap,
  fasImage,
  fasLanguage,
  fasLightbulb,
  fasLocationArrow,
  fasMagnifyingGlass,
  fasMale,
  fasMapMarkerAlt,
  fasMobileScreenButton,
  faHandHoldingHeart,
  fasHeart,
  fasLock,
  fasPencilAlt,
  fasPhoneSquare,
  fasPhone,
  fasPlus,
  fasQuestion,
  fasQuestionCircle,
  fasQrcode,
  fasRedo,
  fasRocket,
  fasSearch,
  fasStar,
  fasStopwatch,
  fasSpinner,
  fasTimes,
  faTimesCircle,
  fasUniversity,
  fasLink,
  fasUser,
  fasUserAstronaut,
  fasUsers,
  fasGlobeAmericas,
  fasCreditCard,
  fasUserEdit,
  fasUserShield,
  fasSignOutAlt,
  fasPaperclip,
  fasFile,
  fasTrash,
  fasFileAlt,
  fasEnvelopeOpen,
  fasFlag,
  fasEllipsisH,
  fasShieldAlt,
  fasShieldHeart,
  fasCaretRight,
  fasCaretDown,
  fasEdit,
  fasCloudDownloadAlt,
  fasBell,
  fasCheckSquare,
  fasCircle,
  fasCloudUploadAlt,
  fasClock,
  fasInfoCircle,
  fasShare,
  fasAdjust,
  fasHome,
  fasGlobe,
  fasVideo,
  fasXmark,
  /** */
];
