/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';
import { generateOptimisticId } from '@pluralcom/plural-js-utils';

import { commitMutation } from '../..';
import { localStorageHelpers } from '../../../utils';

const mutation = graphql`
  mutation CreateSkillMutation($input: CreateSkillInput!) {
    createSkill(input: $input) {
      newSkillEdge {
        node {
          id
          title
          is_draft
          is_ghost
          is_hobby
          published
          published_at
          # Optimistic update for profile skills
          ...SkillCardMini_skill @arguments(isSkillCardPreview: true)
          user {
            id
            skills {
              # Update total count
              totalCount
            }
          }
        }
      }
      error {
        message
      }
    }
  }
`;

export default (
  {
    title,
    published = false,
    is_hobby,
    is_ghost,
  }: {
    title: string,
    published?: boolean,
    is_hobby?: boolean,
    is_ghost?: boolean,
  },
  { totalCount } = {},
): Promise<Object> => {
  const userId = localStorageHelpers.getUser()?.id;

  const configs = [
    {
      type: 'RANGE_ADD',
      parentID: localStorageHelpers.getUser()?.id,
      connectionInfo: [
        {
          key: 'SkillsTab_skills',
          rangeBehavior: 'append',
        },
        {
          key: 'ModalSkillSelector_skills',
          rangeBehavior: 'append',
        },
        {
          key: 'AbilityTab_skills',
          rangeBehavior: 'append',
        },
      ],
      edgeName: 'newSkillEdge',
    },
  ];

  return new Promise((resolve, reject) => {
    commitMutation({
      mutation,
      variables: {
        input: {
          title,
          published,
          is_hobby,
          is_ghost,
        },
      },
      optimisticResponse: userId
        ? {
            createSkill: {
              newSkillEdge: {
                node: {
                  id: generateOptimisticId('SKILL'),
                  title,
                  is_draft: !published,
                  is_ghost: is_ghost ?? false,
                  is_hobby: is_hobby ?? false,
                  published: published ?? false,
                  published_at: published ? new Date().toISOString() : null,
                  user: {
                    id: userId,
                    skills: {
                      totalCount: (totalCount ?? 0) + 1,
                    },
                  },
                },
              },
              error: null,
            },
          }
        : undefined,
      configs,
      onCompleted: resolve,
      onError: reject,
    });
  });
};
