/* @flow */
import React from 'react';

import FontAwesomeIcon from '../../../FontAwesomeIcon/FontAwesomeIcon';
import style from './Loader.module.scss';

const Loader = ({ isLoading }: { isLoading?: boolean }) =>
  isLoading ? (
    <div className={style['loader-container']}>
      <FontAwesomeIcon
        className={style.loader}
        icon={['fas', 'spinner']}
        pulse
        scale="2x"
      />
    </div>
  ) : null;

export default Loader;
