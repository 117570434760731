import React from 'react';

import { LegalButtonFloating } from '@pluralcom/blueprint';
import { analyticsHelpers } from '@pluralcom/plural-js-utils';

import { useHistory } from 'react-router-dom';
import { useMedia } from 'use-media';
import { mixpanelHelpers, responsive } from '../../utils';

import styles from './ToUPPButton.module.scss';

const ToUPPButton = () => {
  const history = useHistory();
  const isMinLG: boolean = useMedia({ minWidth: responsive.minDeviceWidthLG });

  return (
    <LegalButtonFloating
      size={isMinLG ? 'md' : 'xs'}
      className={styles.button}
      onClick={() => {
        mixpanelHelpers.trackEvent(
          analyticsHelpers.events.SEARCH_GENERAL_BUTTON_POLICIES.name,
        );
        history.push('/info/terms-of-use');
      }}
    />
  );
};

export default ToUPPButton;
