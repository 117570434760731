/* @flow */
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { inputsMaxLengths } from '../../assets/data';

import TileInput from '../ModalForm/components/TileInput/TileInput';
import SelectedTileItem from '../ModalForm/components/SelectedTileItem/SelectedTileItem';
import UserAvatar from '../ModalForm/components/UserAvatar/UserAvatar';
import EmptyUserAvatar from '../ModalForm/components/EmptyUserAvatar/EmptyUserAvatar';

import InputSkillList from './components/InputSkillList/InputSkillList';
import { useOutsideClick } from '../../hooks';

import styles from './InputSkill.module.scss';

type Props = {
  otherUserId?: ?string,
  viewer: Object,
  selectedSkill: ?{ id: string, title: string },
  onSelectAutosuggest: Function,
  placeholder?: string,
  skill_id?: string,
  inputProps?: Object,
  skipOwnSkills?: boolean,
  className?: ?string,
  skillTitleToPreselect?: ?string,
  initialValue?: string,
};

const InputSkill = ({
  viewer,
  otherUserId,
  selectedSkill,
  onSelectAutosuggest,
  placeholder = 'Select or type skill',
  skill_id,
  inputProps,
  skipOwnSkills,
  className,
  skillTitleToPreselect,
  initialValue = '',
}: Props) => {
  const inputRef = useRef(null);
  const [isAutosuggestOpen, setIsAutosuggestOpen] = useState(false);
  const [value, setValue] = useState(initialValue);
  const wrapperRef = useRef(null);
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsAutosuggestOpen(false);
    }
  };
  /** Close autosuggest on outside click */
  useOutsideClick(handleClickOutside, 'mousedown');

  /** Focus input when auto suggest is opened */
  useEffect(() => {
    if (isAutosuggestOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isAutosuggestOpen]);

  /** Preselect skill if needed */
  useEffect(() => {
    if (skillTitleToPreselect) {
      onSelectAutosuggest({ title: skillTitleToPreselect });
    }
  }, [skillTitleToPreselect, onSelectAutosuggest]);

  return (
    <div ref={wrapperRef} className={classNames([styles.container, className])}>
      {selectedSkill && !isAutosuggestOpen ? (
        <SelectedTileItem
          title={selectedSkill.title}
          onClick={() => {
            onSelectAutosuggest(null);
            setIsAutosuggestOpen(true);
          }}
          renderLeftContent={() =>
            selectedSkill.user ? (
              <UserAvatar
                user={selectedSkill.user}
                avatar={selectedSkill.user.avatar}
              />
            ) : (
              <EmptyUserAvatar />
            )
          }
        />
      ) : (
        <TileInput
          placeholder={placeholder}
          onFocus={() => {
            setIsAutosuggestOpen(true);
          }}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          maxLength={inputsMaxLengths.skillTitle}
          {...inputProps}
          inputRef={(ref) => {
            inputRef.current = ref;
          }}
        />
      )}
      <InputSkillList
        viewer={viewer}
        otherUserId={otherUserId}
        value={value}
        isAutosuggestOpen={isAutosuggestOpen}
        onSelectAutosuggest={(skill) => {
          setIsAutosuggestOpen(false);
          onSelectAutosuggest(skill);
        }}
        skill_id={skill_id}
        skipOwnSkills={skipOwnSkills}
      />
    </div>
  );
};

export { InputSkill as PureInputSkill };
export default createFragmentContainer(InputSkill, {
  viewer: graphql`
    fragment InputSkill_viewer on ViewerType
    @argumentDefinitions(
      other_user_id: { type: "String", defaultValue: "" }
      title_term: { type: "String", defaultValue: "" }
      skipOwnSkills: { type: "Boolean", defaultValue: false }
    ) {
      ...InputSkillList_viewer
        @arguments(
          other_user_id: $other_user_id
          title_term: $title_term
          skipOwnSkills: $skipOwnSkills
        )
    }
  `,
});
