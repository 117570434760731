/* @flow */
import get from 'lodash/get';
import urlJoin from 'url-join';
import { pluralUrls } from '@pluralcom/plural-js-utils';

import browserHistory from '../browserHistory';
import innerRoutesHelpers from '../innerRoutesHelpers/innerRoutesHelpers';
import environment from '../environment/environment';

const DEFAULT_ROUTE = '/search';

/** Gets the web URL based on env */
const _getWebUrl = (env: string): string => {
  // Go to beta url instead of staging in case of staging env
  if (env === 'staging') {
    return pluralUrls.BETA_WEB_URL;
  }
  return pluralUrls.getWebUrl(env);
};

/**
 * Handles a successful branch link and routes in the app
 */
const handeBranchLinking = (data: {
  data_parsed: {
    $desktop_url?: ?string,
    contentMetadata?: {
      customMetadata: {
        to?: string,
        username?: string,
        skill_id?: string,
        thread_id?: string,
        payment_id?: string,
        deal_id?: string,
      },
    },
  },
}) => {
  const { data_parsed } = data;
  /* Note that branch auto routes to desktop_url if exists */
  const { $desktop_url } = data_parsed;
  const {
    to,
    username,
    user_id,
    skill_id,
    thread_id,
    payment_id,
    deal_id,
    room_name,
    env,
  } =
    get(data_parsed, 'contentMetadata.customMetadata') ||
    data_parsed.contentMetadata ||
    data_parsed;

  /**
   * If branch cannot find a $desktop_url value, then we'll
   * grab the routing information from the `customMetadata` field.
   * This metadata is embeded at the time of the link creation
   */
  if (!$desktop_url && to) {
    let route = DEFAULT_ROUTE;
    switch (to) {
      case 'profile':
        if (username || user_id) {
          route = innerRoutesHelpers.getProfileLink({
            username,
            user_id,
          });
        }
        break;
      case 'skill':
        if (skill_id) {
          route = innerRoutesHelpers.getSkillLink({
            id: skill_id,
          });
        }
        break;
      // @todo split to message and activity?
      case 'activity':
        route = thread_id
          ? innerRoutesHelpers.getThreadLink(thread_id)
          : '/activities';
        break;
      case 'deal':
        route = innerRoutesHelpers.getDealLink(deal_id);
        break;
      case 'message':
        route = thread_id
          ? innerRoutesHelpers.getThreadLink(thread_id)
          : '/messages';
        break;
      case 'network':
        route = '/profile/contacts';
        break;
      case 'payment':
        route = innerRoutesHelpers.getPaymentLink(payment_id);
        break;
      case 'payout_method':
        route = innerRoutesHelpers.getPayoutMethodLink();
        break;
      case 'room':
        route = innerRoutesHelpers.getRoomLink(room_name);
        break;
      default:
        route = DEFAULT_ROUTE;
    }
    // if env is different than host env -> go to other env website.
    if (env && env !== environment.getHostEnv()) {
      window.location.href = urlJoin(_getWebUrl(env), route);
    } else {
      // route normally in app
      browserHistory.push(route);
    }
  }
};

export default handeBranchLinking;
