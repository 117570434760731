/* @flow */
import React from 'react';
import classNames from 'classnames';
import { placeholderColorsHelpers } from '@pluralcom/plural-js-utils';

import styles from './ImagePlaceholder.module.scss';

type Props = {
  color?: string,
  uuid?: ?string,
  className?: ?string,
};

/**
 * Use this component if an image doesn't exist
 * @example
 *  <ImagePlaceholder uuid={user.id} className={style['img-placeholder']} >
 * */

const ImagePlaceholder = ({ color: inColor, uuid, className }: Props) => {
  const color =
    inColor ||
    (uuid && placeholderColorsHelpers.stringToColorObject(uuid).color);
  const background_color = placeholderColorsHelpers.mapColorCode(
    placeholderColorsHelpers.SKILL_MEDIA_PLACEHOLDER,
    color,
  ).color;
  return (
    <div
      className={classNames(styles.placeholder, className)}
      style={
        color
          ? {
              backgroundColor: background_color,
              color,
            }
          : {}
      }
    />
  );
};

export default ImagePlaceholder;
