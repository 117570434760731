/* @flow */
import { useEffect, useState } from 'react';
import queryString from 'query-string';
import omit from 'lodash/omit';

import { CompleteCheckoutSession } from '../../graphql/mutations';
import HistoryHelpers from '../../services/HistoryHelpers/HistoryHelpers';

/** List of completed IDs as cache to avoid duplicate requests */
const COMPLETED_IDS = [];
/** Max length of the cache */
const COMPLETED_IDS_MAX_LENGTH = 20;

/**
 * Completes a stripe checkout session with the backend
 */
export const completeCheckoutSession = async ({
  location,
  refetch,
  setIsLoading = () => {},
  setError = () => {},
}) => {
  const queryParams = queryString.parse(location?.search || {});
  const { sc_checkout, sc_sid } = queryParams;
  if (sc_checkout === 'cancel') {
    HistoryHelpers.replaceUrlLocation({
      search: queryString.stringify(omit(queryParams, ['sc_checkout'])),
    });
    return;
  }
  /** If params are not available -> do nth */
  if (sc_checkout !== 'success' || !sc_sid || COMPLETED_IDS.includes(sc_sid)) {
    return;
  }
  setIsLoading(true);
  const res = await CompleteCheckoutSession({
    checkout_session_id: queryParams.sc_sid,
  });
  /** Remove checkout used params */
  HistoryHelpers.replaceUrlLocation({
    search: queryString.stringify(omit(queryParams, ['sc_checkout', 'sc_sid'])),
  });
  if (res?.completeCheckoutSession?.error) {
    setError(res.completeCheckoutSession.error);
    setIsLoading(false);
    return;
  }
  /** Add the completed id to the cache */
  COMPLETED_IDS.push(sc_sid);
  /** Garbage collect on cache arrays */
  if (COMPLETED_IDS.length > COMPLETED_IDS_MAX_LENGTH) {
    COMPLETED_IDS.splice(0, Math.floor(COMPLETED_IDS.length / 2));
  }
  /** To ensure we fetch the new data from the backend */
  if (refetch) {
    refetch(
      null,
      null,
      () => {
        setIsLoading(false);
      },
      { force: true },
    );
  } else {
    window.location.reload();
  }
};

const useCompleteCheckoutSession = (
  location: Location,
  relay: Relay,
): { isLoading: boolean, error: Object, setError: Function } => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const { refetch } = relay;

  /** Complete the checkout session on location change */
  useEffect(() => {
    completeCheckoutSession({ location, refetch, setIsLoading, setError });
  }, [location, refetch]);

  return { isLoading, error, setError };
};

export default useCompleteCheckoutSession;
