/* @flow */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import graphql from 'babel-plugin-relay/macro';

import { QueryRenderer } from '../../graphql';

import LoadingLayout from '../LoadingLayout/LoadingLayout';
import ModalWizardMultiple from '../ModalWizardMultiple/ModalWizardMultiple';
import FormReferUser from '../FormReferUser/FormReferUser';

import styles from './ModalReferUser.module.scss';

/**
 * Provide modal for people selector list
 * */

type Props = {
  isOpen?: boolean,
  otherUserId?: string,
  onSelectPerson?: Function,
  toggle?: Function,
  userSearchPublic?: {
    id: string,
    user: {
      id: string,
    },
    tag: {
      id: string,
      title: string,
    },
  },
};

const ModalReferUser = ({
  otherUserId = null,
  onSelectPerson,
  toggle,
  userSearchPublic,
  ...rest
}: Props) => {
  const formReferUserRef = useRef(null);
  const [isValid, setIsValid] = useState(false);

  /**
   * handle click next button
   * submit form and close modal
   */
  const _onNext = async () => {
    formReferUserRef.current?.submit();
    toggle();
  };

  return (
    // @todo remove ModalWizardMultiple and use new blueprint modals with bottomBarProps
    <ModalWizardMultiple
      contentClassName={styles.content}
      bodyClassName={styles.body}
      backButtonProps={{
        text: 'Back',
        className: styles['btn-back'],
        onClick: () => {
          toggle();
        },
      }}
      nextButtonProps={{
        text: 'Send',
        className: styles['btn-send'],
        onClick: _onNext,
        // disabled buttons if form is not valid. ie: if no referredUser is selected
        disabled: !isValid,
      }}
      toggle={toggle}
      {...rest}
    >
      <QueryRenderer
        variables={{
          otherUserId,
          skipOwnSkills: !otherUserId,
        }}
        query={graphql`
          query ModalReferUserQuery(
            $otherUserId: String
            $skipOwnSkills: Boolean!
          ) {
            viewer {
              ...FormReferUser_viewer
                @arguments(
                  otherUserId: $otherUserId
                  skipOwnSkills: $skipOwnSkills
                )
            }
          }
        `}
        render={({ props: queryProps }) => {
          if (queryProps) {
            return (
              <FormReferUser
                viewer={queryProps.viewer}
                otherUserId={otherUserId}
                onSelectPerson={onSelectPerson}
                userSearchPublic={userSearchPublic}
                onValidationChange={setIsValid}
                ref={formReferUserRef}
              />
            );
          }
          return <LoadingLayout />;
        }}
      />
    </ModalWizardMultiple>
  );
};

export default ModalReferUser;
