/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import requestSubscription from '../requestSubscription/requestSubscription';

const subscription = graphql`
  subscription sellerInformationSubscribeSubscription {
    sellerInformationSubscribe {
      # @gql-reused Used in [PayoutMethods, UpdateStripeAccountInfoMutation, sellerInformationSubscribe] Ensure you update -- @todo make fragment and reuse
      id
      first_name
      last_name
      line1
      line2
      postal_code
      city
      state
      country
      ssn_last_4_provided
      dob
      # gender
      id_number_provided
      requirements {
        id
        current_deadline
        currently_due
        disabled_reason
        disabled_reason_text
        errors {
          code
          reason
          requirement
        }
        eventually_due
        past_due
        pending_verification
      }
      verification {
        id
        status
        document {
          id
          details
          front
          back
        }
        additional_document {
          id
          details
          front
          back
        }
      }
    }
  }
`;

const sellerInformationSubscribe = () =>
  requestSubscription({
    subscription,
  });

export default sellerInformationSubscribe;
