/* @flow */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import type { Environment } from 'react-relay';
import { TouchableOpacity } from '@pluralcom/blueprint';

import FontAwesomeIcon from '../FontAwesomeIcon/FontAwesomeIcon';
import Modal from '../Modal/Modal';
import ButtonClose from '../ButtonClose/ButtonClose';
import { ReportEntityMutation } from '../../graphql/mutations';

import styles from './ModalReport.module.scss';

type Props = {
  environment: Environment,
  id?: string,
  isOpen?: boolean,
  toggle: Function,
  entityType?: string,
};

const types = {
  SPAM: 'spam_scam',
  OFFENCIVE: 'offensive',
  PRETENDING: 'impersonation',
  OTHER: 'other',
};

const ModalReport = ({
  environment = {},
  id,
  toggle,
  entityType,
  isOpen = false,
}: Props) => {
  const [type, setType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  /** Reset type on modal close */
  useEffect(() => setType(''), [isOpen]);

  const onButtonPress = (selectedType) => () => setType(selectedType);
  const isActive = (selectedType) => selectedType === type;

  function closeModal() {
    setIsLoading(false);
    return toggle && toggle();
  }

  const onMarkAsWrong = (e) => {
    e.stopPropagation();
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    ReportEntityMutation(environment)(id, entityType, type)
      .then(closeModal)
      .catch(closeModal);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className={styles.modal}>
      <div className={styles.container}>
        <ButtonClose
          absolute
          size="md"
          uiType="lite"
          onClick={toggle}
          className={styles['btn-close']}
        />
        <h2 className={styles.title}>Let Us Know What&apos;s Going On</h2>
        <p className={styles.text}>
          We use your feedback to help us learn when something&apos;s not right.
          This will only be shared with Plural.
        </p>
        <TouchableOpacity
          className={classNames(
            styles.btn,
            isActive(types.SPAM) && styles['btn-active'],
          )}
          onClick={onButtonPress(types.SPAM)}
        >
          Spam or Scam
        </TouchableOpacity>
        <TouchableOpacity
          className={classNames(
            styles.btn,
            isActive(types.OFFENCIVE) && styles['btn-active'],
          )}
          onClick={onButtonPress(types.OFFENCIVE)}
        >
          Offensive
        </TouchableOpacity>
        <TouchableOpacity
          className={classNames(
            styles.btn,
            isActive(types.PRETENDING) && styles['btn-active'],
          )}
          onClick={onButtonPress(types.PRETENDING)}
        >
          Pretending to Be Someone
        </TouchableOpacity>
        <TouchableOpacity
          className={classNames(
            styles.btn,
            isActive(types.OTHER) && styles['btn-active'],
          )}
          onClick={onButtonPress(types.OTHER)}
        >
          Other
        </TouchableOpacity>

        {type && (
          <TouchableOpacity
            className={classNames(
              styles.btn,
              styles['btn-active'],
              styles['btn-send'],
            )}
            disabled={isLoading}
            onClick={onMarkAsWrong}
          >
            {isLoading ? (
              <FontAwesomeIcon
                className={styles.icon}
                icon={['fas', 'spinner']}
                pulse
                scale="2x"
              />
            ) : (
              'Send Feedback'
            )}
          </TouchableOpacity>
        )}
      </div>
    </Modal>
  );
};

export default ModalReport;
